<!--Wish List card option menu-->
<template>
    <base-card-option-menu :uuid="props.list.uuid">
        <template #actions>
            <copy-link :copy-url="list.url" />
            <template
                v-if="
                    (!props.list.is_owner && $page.props.auth.user) ||
                    $page.props.component === 'bookmarks'
                "
            >
                <bookmark-wish-list
                    :list="props.list"
                    :logged-in="$page.props.auth.user"
                />
                <hr class="popover__option--divider" />
            </template>
            <hr
                class="popover__option--divider"
                v-if="
                    ($page.props.component !== 'Bookmarks' ||
                        $page.props.auth.user) &&
                    props.list.is_owner
                "
            />
            <delete-wish-list
                :list="list"
                v-if="props.list.is_owner"
            ></delete-wish-list>
        </template>
    </base-card-option-menu>
</template>
<script setup>
import BaseCardOptionMenu from "@/Components/OptionMenus/BaseCardOptionMenu.vue";
import BookmarkWishList from "@/Components/Actions/BookmarkWishList.vue";
import CopyLink from "@/Components/Actions/CopyLink.vue";
import DeleteWishList from "@/Components/Actions/DeleteWishList.vue";

const props = defineProps({
    list: {
        type: Object,
        default: function () {
            return {};
        },
    },
    owner: {
        type: Object,
        default: function () {
            return {};
        },
    },
});
</script>
