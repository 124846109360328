<template>
    <WishListLayout> </WishListLayout>
</template>
<script setup>
import WishListLayout from "@/Layouts/WishListLayout.vue";
import { useAuthUser } from "@/composables/useAuthUser";
import { useWishList } from "@/composables/useWishList";

const user = useAuthUser();
const { giftlist, isExpertList } = useWishList();
</script>
