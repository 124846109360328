<template>
    <section
        class="masthead-visual masthead-visual--is-profile"
        v-show="user.hasOwnProperty('username')"
    >
        <div class="masthead-visual__primary">
            <div class="masthead-visual__primary--layer-upper">
                <div class="masthead-visual__content">
                    <figure class="masthead-visual__profile-picture">
                        <div
                            class="masthead-visual__profile-badge"
                            v-if="isUserOrganization"
                        >
                            <span class="profile-user__organization-badge"
                                >Organization</span
                            >
                        </div>
                        <a
                            :href="userUrl(user.username)"
                            title="user.presentation_name"
                            v-if="user.hasOwnProperty('avatar')"
                        >
                            <img
                                :src="user.avatar.profile_photo"
                                :alt="user.presentation_name"
                            />
                        </a>
                    </figure>
                    <h1 class="masthead-visual__displayname">
                        {{ user.presentation_name }}
                    </h1>
                    <h2 class="masthead-visual__username">
                        @{{ user.username }}
                    </h2>
                    <!--Social accounts-->
                    <aside class="masthead-visual__social-accounts">
                        <ul class="items">
                            <!--Website-->
                            <li class="item" v-if="user.website_url">
                                <a
                                    class="link"
                                    :href="user.website_url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Homepage"
                                >
                                    <i class="icon"
                                        ><svg-social-website></svg-social-website
                                    ></i>
                                </a>
                            </li>
                            <!--Instagram-->
                            <li
                                class="item"
                                v-if="user.social_instagram_username"
                            >
                                <a
                                    class="link"
                                    :href="`https://instagram.com/${user.social_instagram_username}`"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Instagram"
                                >
                                    <i class="icon"
                                        ><svg-social-instagram></svg-social-instagram
                                    ></i>
                                </a>
                            </li>
                            <!--Facebook-->
                            <li class="item" v-if="user.social_facebook_url">
                                <a
                                    class="link"
                                    :href="user.social_facebook_url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Facebook"
                                >
                                    <i class="icon"
                                        ><svg-social-facebook></svg-social-facebook
                                    ></i>
                                </a>
                            </li>
                            <!--Twitter-->
                            <li
                                class="item"
                                v-if="user.social_twitter_username"
                            >
                                <a
                                    class="link"
                                    :href="`https://x.com/${user.social_twitter_username}`"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Twitter"
                                >
                                    <i class="icon"
                                        ><svg-social-twitter></svg-social-twitter
                                    ></i>
                                </a>
                            </li>
                        </ul>
                    </aside>
                </div>
            </div>
            <div
                class="masthead-visual__primary--layer-lower"
                :class="{ 'is-default': !userHeaderPhoto }"
            ></div>
        </div>
        <div class="masthead-visual__secondary" v-if="isUserProfileView">
            <!--Short description-->
            <div class="masthead-visual__description" v-if="user.description">
                <template v-if="shouldEllipsesDescription">
                    <p>{{ userDescriptionEllipsed }}&hellip;</p>
                    <p>
                        <button
                            class="more"
                            type="button"
                            @click="openDescriptionInModalog"
                        >
                            Read More
                        </button>
                    </p>
                </template>
                <template v-else>
                    <p>{{ user.description }}</p>
                </template>
            </div>
            <!--User (owner) profile options menu-->
            <UserProfileOwnerOptionsMenu v-if="isOwner" />
            <div class="masthead-visual__list-options" v-if="!isOwner">
                <FollowingStatusButton :user="user" />
                <!--TODO: Implement profile options menu when viewing other users profile page-->
                <!--<div>-->
                <!--    <user-profile-options-menu-->
                <!--        :user="user"-->
                <!--    ></user-profile-options-menu>-->
                <!--</div>-->
            </div>
        </div>
    </section>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useIsOwner } from "@/composables/useIsOwner";
import { useMastheadImageUrls } from "@/composables/useMastheadImageUrls";
import { useModalogStore } from "@/stores/modalog";
import FollowingStatusButton from "@/Components/Actions/FollowingStatusButton.vue";
// --- Mixins ---
import urlUtilitiesMixin from "@/Mixins/urlUtilities.js";

// --- SVG ---
import SvgSocialFacebook from "~/svg/social-facebook.svg";
import SvgSocialInstagram from "~/svg/social-instagram.svg";
import SvgSocialTwitter from "~/svg/social-twitter.svg";
import SvgSocialWebsite from "~/svg/social-website.svg";
// import Throbber from '../utilities/throbber'
// --- Components ---
import UserProfileOwnerOptionsMenu from "@/Components/User/UserProfileOwnerOptionsMenu.vue";
// import UserProfileOptionsMenu from '../option-menus/UserProfileOptionsMenu'

const props = defineProps({
    user: {
        default: null,
        required: false,
        type: Object,
    },
});

defineOptions({
    mixins: [urlUtilitiesMixin],
});

const modalogStore = useModalogStore();
const { showModalog } = modalogStore;

const user = computed(() =>
    props.user ? props.user : usePage().props.owner?.data,
);
const isOwner = useIsOwner();

// --- Computed ---
const currentView = computed(() => {
    return usePage().props.component;
    // return this.$store.state.view.current
});

const isLoggedIn = computed(() => {
    return usePage().props.auth.user.data;
    // return this.$store.state.isLoggedIn
});

// isThrobbing () {
//     return this.$store.state.api.isThrobbing
// },

const isUserOrganization = computed(() => {
    return user.value.is_organization;
});

const isUserProfileView = computed(() => {
    return route().current() === "user.giftlists";
});

const shouldEllipsesDescription = computed(() => {
    return user.value.description?.length > 80;
});

const userDescriptionEllipsed = computed(() => {
    return user.value.description?.slice(0, 80);
});

const userHeaderPhoto = computed(() => {
    return user.value.cover_photo;
});

const openDescriptionInModalog = () => {
    modalogStore.showModalog({
        type: "ModalogContent",
        props: {
            content: user.value.description_formatted,
            title: `About ${user.value.first_name}`,
        },
        options: {
            hasConfirmButton: false,
        },
        isConfirmDisabled: false,
    });
};

const mastheadImageUrls = ref(useMastheadImageUrls(user.value.cover_photo));

watch(
    () => usePage().props.owner?.data.cover_photo,
    (newVal) => {
        mastheadImageUrls.value = useMastheadImageUrls(newVal);
    },
);
</script>
<style scoped>
@media only screen and (max-width: 37.5rem) {
    .masthead-visual__primary--layer-lower {
        background-image: v-bind("mastheadImageUrls.small1x");
    }
}

@media only screen and (max-width: 37.5rem) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (max-width: 37.5rem) and (min-resolution: 192dpi) {
    .masthead-visual__primary--layer-lower {
        background-image: v-bind("mastheadImageUrls.small2x");
    }
}

@media only screen and (min-width: 37.5rem) {
    .masthead-visual__primary--layer-lower {
        background-image: v-bind("mastheadImageUrls.large1x");
    }
}

@media only screen and (min-width: 37.5rem) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-width: 37.5rem) and (min-resolution: 192dpi) {
    .masthead-visual__primary--layer-lower {
        background-image: v-bind("mastheadImageUrls.large2x");
    }
}
</style>
