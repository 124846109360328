<!--
  This is the abstract Modalog component implementation.
  All UI modalog components should implement this interface.
-->
<template>
    <Transition :name="transitionName" mode="out-in">
        <div
            class="dialog"
            :class="[
                { 'dialog--fullscreen': props.isFullscreen },
                props.dialogClass,
            ]"
            v-show="hasModalog"
        >
            <div
                class="dialog__body"
                ref="dialogBody"
                :class="dialogBodyClass"
                :style="props.dialogBodyStyles"
            >
                <button
                    class="btn btn-link dialog__close"
                    @click="close"
                    v-if="props.hasCloseX || !props.hasFooter"
                >
                    <svg-x></svg-x>
                </button>
                <header class="dialog__header" v-if="props.hasHeader">
                    <h2 class="dialog__title">
                        <slot name="title"></slot>
                    </h2>
                </header>
                <slot name="notice"></slot>
                <slot name="item"></slot>
                <div
                    class="dialog__content"
                    :class="[
                        props.contentClass,
                        { 'dialog__content--no-header': !props.hasHeader },
                    ]"
                >
                    <slot name="contentTitle"></slot>
                    <div
                        class="content-errors"
                        v-if="hasErrors && !hasInlineErrors"
                    >
                        <errors></errors>
                    </div>
                    <slot name="content"></slot>
                </div>
                <footer class="dialog__footer" v-if="hasFooter">
                    <Throbber
                        v-if="isThrobbing && shouldFooterThrob"
                    ></Throbber>
                    <slot name="footer" v-else>
                        <slot name="cancelButton">
                            <button
                                class="strip-btn dialog__action-button"
                                @click="close"
                            >
                                Cancel
                            </button>
                        </slot>
                        <slot name="confirmButton">
                            <button
                                class="strip-btn dialog__action-button dialog__action-button--primary"
                                @click="handleClick"
                                :disabled="isConfirmDisabled"
                            >
                                {{ confirmButtonText }}
                            </button>
                        </slot>
                    </slot>
                </footer>
            </div>
        </div>
    </Transition>
</template>
<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useModalogStore } from "@/stores/modalog.js";
// import Errors from '../utilities/errors.vue'
import SvgX from "~/svg/ui-x-skinny.svg";
import Throbber from "@/Components/Throbber/Throbber.vue";
/**
 * Modalog shell component. All modalogs should extend this component.
 * Example:
 * <modalog :has-header="true" contentClass="dialog__content--no-pad">
 *     <template slot="title">Hi, I'm a Modalog, Mom!</template>
 *     <template slot="content">Aren't I awesome?</template>
 * </modalog>
 */

const modalogStore = useModalogStore();

const props = defineProps({
    /* Optional: Change the text for the confirm button. */
    confirmButtonText: {
        type: String,
        default: "Ok",
        required: false,
    },
    /* Option additional class on the content slot, i.e. `dialog__content--no-pad` */
    contentClass: {
        type: String,
        default: null,
    },
    /* Optional class modifier for main `.dialog` element. */
    dialogClass: {
        type: String,
        default: null,
    },
    /* Optionally display a close button "X" */
    hasCloseX: {
        type: Boolean,
        default: false,
        required: false,
    },
    /* Optionally hide the modalog footer */
    hasFooter: {
        type: Boolean,
        default: true,
        required: false,
    },
    /* Should we show the header */
    hasHeader: {
        type: Boolean,
        default: false,
    },
    /* If extending component implements errors inline, don't display global errors component. */
    hasInlineErrors: {
        type: Boolean,
        default: false,
    },
    /* Is the modalog of the fullscreen variety? This applies specific classes to elements. */
    isFullscreen: {
        type: Boolean,
        default: false,
        required: false,
    },
    /* Where or not footer elements should be replaced with throbber when API is throbbing. */
    shouldFooterThrob: {
        type: Boolean,
        default: true,
        required: false,
    },
    /* CSS size modifier (small, medium, large) */
    size: {
        type: String,
        default: "small",
    },
    /* Dynamic transition name */
    transitionName: {
        type: String,
        default: "dialog",
    },
});

onMounted(() => addEventListeners());

const dialogBodyStyles = reactive({});
/**
 * Detect Internet Explorer 6-11, sigh.
 * @url https://jsfiddle.net/311aLtkz/
 */
const isIE = ref(/*@cc_on!@*/ false || !!document.documentMode);
const maxDialogBodyHeight = ref(null);

// --- Computed
const dialogBodyClass = computed(() => {
    if (props.isFullscreen) {
        return "dialog__body--fullscreen";
    } else {
        return `dialog__body--${props.size}`;
    }
});

const hasErrors = computed(() => {
    // return (this.$store.state.api.hasErrors.length > 0);
});

const hasModalog = computed(() => {
    return modalogStore.hasModalog;
});

const isConfirmDisabled = computed(() => {
    return modalogStore.isConfirmDisabled;
});

const isThrobbing = computed(() => {
    return modalogStore.isThrobbing;
    // return this.$store.state.api.isThrobbing;
});

//--- Methods
function addEventListeners() {
    document.addEventListener("keydown", (e) => {
        if (e.keyCode === 27) {
            modalogStore.closeModalog();
        }
    });
}

function close() {
    modalogStore.closeModalog();
}

function handleClick() {
    // this.$store.commit('api/setIsPending', true);
}

function handleIE() {
    // Make sure the modalog is open and that the browser is IE.
    // if (this.hasModalog && this.isIE) {
    //     // If the scrollHeight of the modalog body is greater than 86vh,
    //     // we'll apply set the height to 86vh
    //     if (this.$refs.dialogBody.scrollHeight > this.maxDialogBodyHeight) {
    //         this.$nextTick( function () {
    //             this.dialogBodyStyles = {
    //                 height: this.maxDialogBodyHeight + 'px'
    //             }
    //         })
    //     }
    // }
}

// export default {
//     created () {
//         this.addEventListeners()
//     },
//     mounted () {
//         // Set the max modalog body height to 86vh.
//         // Only used if IE.
//         this.maxDialogBodyHeight = (window.innerHeight * 0.86)
//         this.$watch('hasModalog', this.handleIE, {
//             deep: true,
//             immediate: true
//         })
//     },
// }
</script>
