<template>
    <Modalog
        :has-header="false"
        :has-inline-errors="true"
        :transition-name="transitionName"
        contentClass="dialog__content--has-media"
        size="large"
    >
        <template #content>
            <!--Item image(s)-->
            <div class="content-media">
                <div class="content-media__image">
                    <div class="add-item-media">
                        <!-- BACK END: Show this when the magic returns a single image: -->
                        <div
                            class="add-item-media__single"
                            v-if="!images.length"
                        >
                            <div class="add-item-media__image">
                                <img
                                    :src="product.image_url"
                                    :alt="product.name"
                                />
                            </div>
                            <!-- .add-item-media__image -->
                        </div>
                        <!-- .add-item-media__single -->
                        <!-- ========================================================== -->
                        <!-- BACK END: Show this when the magic returns multiple images: -->
                        <div
                            class="add-item-media__multiple"
                            v-if="images.length"
                        >
                            <div class="add-item-media__images">
                                <div
                                    class="add-item-media__image"
                                    v-for="img in images"
                                >
                                    <label>
                                        <input
                                            type="radio"
                                            name="item-image"
                                            :value="img.url"
                                            class="visuallyhidden"
                                            :checked="
                                                img.url === product.image_url
                                            "
                                            @click="selectImage(img.url)"
                                        />
                                        <span
                                            ><img
                                                :src="img.url"
                                                :alt="product.name"
                                        /></span>
                                    </label>
                                </div>
                                <!-- .add-item-media__image -->
                                <!-- BACK END: This *last* media-image is *always* an option: -->
                                <div
                                    class="add-item-media__image add-item-media__image--none"
                                >
                                    <label>
                                        <input
                                            type="radio"
                                            name="item-image"
                                            value=""
                                            class="visuallyhidden"
                                            @click="selectImage('')"
                                        />
                                        <span>
                                            <img
                                                src="https://res.cloudinary.com/gift-hero/image/upload/v1685819255/lib/img/placeholder-square-1x.png"
                                                srcset="
                                                    https://res.cloudinary.com/gift-hero/image/upload/v1685819255/lib/img/placeholder-square-2x.png 2x
                                                "
                                                height="350"
                                                width="350"
                                                alt="Default Gift Hero image"
                                            />
                                            <em>None</em>
                                        </span>
                                    </label>
                                </div>
                                <!-- .add-item-media__image -->
                            </div>
                            <!-- .add-item-media__images -->
                        </div>
                        <!-- .add-item-media__multiple -->
                        <!-- =========================================================== -->
                    </div>
                    <!-- .add-item-media -->
                </div>
            </div>
            <!--Item details-->
            <div class="content-main">
                <div class="content-errors" v-if="parseError">
                    <!--<errors></errors>-->
                </div>
                <div class="dialog__content--row">
                    <!--Item name-->
                    <label class="form-label" for="item-name">Gift Name</label>
                    <input
                        class="input"
                        type="text"
                        id="item-name"
                        name="item-name"
                        placeholder="Our Wedding Day"
                        required
                        v-model="product.name"
                        autocomplete="off"
                    />
                </div>
                <!--Price / Currency / Quantity-->
                <div
                    class="dialog__content--row dialog__columns dialog__columns--two-thirds"
                >
                    <div class="column">
                        <div class="dialog__columns dialog__columns--two">
                            <!--Item price-->
                            <div class="column">
                                <label class="form-label" for="item-price"
                                    >Price</label
                                >
                                <div
                                    class="input-group"
                                    :class="{ 'is-error': invalidPrice }"
                                >
                                    <div class="input-group-prepend">
                                        <span class="input-group__text">{{
                                            $page.props.currencies[
                                                currencyCodeSelected
                                            ]
                                        }}</span>
                                    </div>
                                    <input
                                        class="input"
                                        type="number"
                                        id="item-price"
                                        name="item-price"
                                        required
                                        pattern="[0-9]+(\.[0-9][0-9]?)?"
                                        min="0"
                                        step="any"
                                        v-model="product.price"
                                        @input="validatePrice"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                            <div class="column column--align-bottom">
                                <user-currency-select
                                    :currency-code="currencyCodeSelected"
                                    @currency-code-selected="
                                        setCurrencyCodeSelected
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <template v-if="!isExpertList">
                            <!--Item quantity-->
                            <label class="form-label" for="item-quantity"
                                >Qty</label
                            >
                            <input
                                class="input"
                                type="number"
                                id="item-quantity"
                                name="item-quantity"
                                min="1"
                                pattern="\d*"
                                required
                                v-model="product.qty_desired"
                                autocomplete="off"
                            />
                        </template>
                    </div>
                </div>
                <!--Default currency selector-->
                <!--<default-currency-inline-modalog :currency-code="selectedCurrency.code" :currency-symbol="selectedCurrency.symbol" v-show="modalogStore.hasInlineModalog"></default-currency-inline-modalog>-->
                <!--Price Errors-->
                <div class="dialog__content--row is-error" v-if="invalidPrice">
                    <div class="form-control__response">
                        Price can only contain numbers and a single period.
                    </div>
                </div>
                <div class="dialog__content--row">
                    <!--Item details-->
                    <label class="form-label is-optional" for="item-comments"
                        >Gift Details</label
                    >
                    <textarea
                        class="textarea"
                        name="item-comments"
                        placeholder="For example: 'the blue one' or 'medium size', etc."
                        rows="2"
                        v-model="product.notes"
                        autocomplete="off"
                        >{{ itemComments }}</textarea
                    >
                </div>
                <div class="whitespace-top-small" v-if="!isMobile">
                    <aside class="hint-panel hint-panel__quick-tip">
                        <i class="icon"><icon-hint /></i>
                        <div class="content">
                            <p>
                                For a super easy way to add gifts, get the
                                <a
                                    href="/browser-plugins"
                                    title="Get the Gift Hero browser extension."
                                    >Gift Hero browser extension</a
                                >.
                            </p>
                        </div>
                    </aside>
                </div>
            </div>
        </template>
        <template #footer>
            <button
                class="strip-btn dialog__action-button"
                @click="closeModalog"
                v-if="!isLoading"
            >
                Cancel
            </button>
            <throbber v-if="isLoading"></throbber>
            <button
                class="strip-btn dialog__action-button dialog__action-button--primary"
                v-bind:disabled="!validData"
                v-on:click="addItem()"
                v-else
            >
                Save
            </button>
        </template>
    </Modalog>
</template>
<script setup>
import { computed, onMounted, reactive, ref, shallowReactive } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import { debounce } from "lodash";
import { useModalogStore } from "@/stores/modalog";
import regex from "@/utilities/regex";
import IconHint from "~/svg/icon-hint.svg";
import Throbber from "@/Components/Throbber/Throbber.vue";
import UserCurrencySelect from "@/Components/forms/UserCurrencySelect.vue";

const modalogStore = useModalogStore();
const { closeModalog } = modalogStore;

// import DefaultCurrencyInlineModalog from '../../modalogs/DefaultCurrencyInlineModalog'
// import Errors from '../../utilities/errors.vue'
// import UserCurrencySelect from '../../forms/user-currency-select.vue'

//--- Props
const props = defineProps({
    data: {
        type: Object,
        default: {},
    } /* Note: This contains all the original parse data. */,
    listType: {
        type: String,
        default: "",
        required: true,
    },
    listUuid: {
        type: String,
        default: "",
    },
});

//--- State
const currencyCodeSelected = ref(
    usePage().props?.auth.user.data.preferred_currency ?? "USD",
);
const isLoading = ref(false);
const selectedImage = ref("");
const product = shallowReactive({
    currency_code: currencyCodeSelected.value,
    name: "",
    qty_desired: 1,
    notes: "",
    list_uuid: "",
    image_url: "",
    product_uuid: "",
});
const images = ref([]);
const isValidPrice = ref(true);
const itemComments = ref("");
const transitionName = ref("fade");

//--- Mounted
onMounted(() => {
    product.product_uuid = props.data.value.uuid;
    product.list_uuid = props.listUuid;

    if (props.data.value.hasOwnProperty("name")) {
        product.name = props.data.value.name;
    } else {
        product.name = "";
    }

    if (!product.name.length) {
        parseError.value = true;
    }

    if (props.data.value.hasOwnProperty("price")) {
        product.price = props.data.value.price;
    } else {
        product.price = "0.00";
    }

    if (
        props.data.value.hasOwnProperty("images") &&
        props.data.value.images.length > 1
    ) {
        images.value = props.data.value.images;
        product.image_url = props.data.value.images[0].url;
    } else {
        if (
            props.data.value.hasOwnProperty("images") &&
            props.data.value.images.length === 1
        ) {
            product.image_url = props.data.value.images[0].url;
        } else {
            product.image_url = props.data.value.image_url.original;
        }
    }
});

//--- Computed
const isExpertList = computed(() => {
    return props.listType === "expert";
});

const parseError = computed({
    set(hasParseError) {
        if (hasParseError) {
            // this.$store.commit('api/setErrors', [`We tried our very best. Unfortunately, we were unable to find as much information about your gift as we would have liked. Please complete the fields below to create a custom gift.`])
        }
    },
    get() {
        return !props.data.value.name.length;
    },
});

const validData = computed(() => {
    return product.name.length && product.qty_desired > 0 && isValidPrice.value;
});

const invalidPrice = computed(() => {
    return !isValidPrice.value;
});

const quantities = computed(() => {
    return [...Array(99).keys()].map((i) => i + 1);

    let data = [];

    for (let i = 1; i < 100; i++) {
        data.push(i);
    }

    return data;
});

// TODO: Add to Inertia Shared Data?
const isMobile = computed(() => {
    return false;
    // return this.$store.state.isMobile;
});

//--- Methods
function addItem() {
    // this.$set(this.product, 'currency_code', this.selectedCurrency.code)

    isLoading.value = true;

    // make with the api call already
    // this.$http.post(endpoint, this.product).then((response) => {
    axios.post(route("item.store"), product).then(
        (response) => {
            console.log(response);

            // const item = response.body.item;
            // const item = 'Success';

            // Update state
            // this.$emit('item-added', item);
            isLoading.value = false;

            resetProduct();

            // this.$store.commit('wishlists/addItem', item)
            // this.$store.dispatch('closeModalog').then(() => { console.log('URL item added.') })
            console.log("URL item added.");

            closeModalog();

            // TODO: Handle `pagination` and `orderBy: sequence`
            router.reload({ only: ["items"] });
        },
        (response) => {
            // error callback
            isLoading.value = false;
            // this.$store.commit('api/setErrors', response.body[0]);
            console.log(response);
        },
    );
}

function hideDialog() {
    this.$emit("hide-dialog");
    // this.$store.commit('hideModal');
    closeModalog();
}

function resetProduct() {
    product.name = "";
    product.qty_desired = 1;
    product.notes = "";
    images.value = [];
}

function selectImage(image) {
    product.image_url = image;
}

function setCurrencyCodeSelected(currencyCode) {
    currencyCodeSelected.value = currencyCode;
    product.currency_code = currencyCode;
}

const validatePrice = debounce(function () {
    // See `/_js/utils/regex.js`
    isValidPrice.value = String(product.price).search(regex.priceInputs) >= 0;
}, 500);

// TODO: This is the inline modalog to save as your default currency when changing in the dropdown.
// export default{
//     watch: {
//         selectedCurrency (selectedCurrency) {
//             this.$store.commit('hasInlineModalog', true)
//         }
//     }
// }
</script>
