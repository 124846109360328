<script setup>
import { computed, onMounted, reactive, ref, unref } from "vue";
import { useModalogStore } from "@/stores/modalog";
import { useVuelidate } from "@vuelidate/core";
import { url as v$url, helpers } from "@vuelidate/validators";
import Throbber from "@/Components/Throbber/Throbber.vue";
import IconHint from "~/svg/icon-hint.svg";
import { isEmpty } from "lodash";
import { usePage } from "@inertiajs/vue3";
import SvgMobileAppCallouut from "~/svg/mobile-app-callout.svg";
import IconGiftCard from "~/svg/icon-gift-card.svg";

const emit = defineEmits(["confirm-custom", "url-parsed"]);
const { closeModalog } = useModalogStore();

const props = defineProps(["listUuid"]);

const custom = reactive({
    name: "",
    url: "",
    price: "0.00",
    qty_desired: 1,
    notes: "",
    list_uuid: props.listUuid,
    photo_uuid: "",
});
const isLoading = ref(false);
const isUrlValid = ref(true);
const minInputLength = ref(4);
const minInputValidationMessage = computed(
    () => `Please enter at least ${minInputLength} characters.`,
);
const type = ref("url");
const url = ref("");
const urlError = ref(false);
const urlErrorMessage = ref(null);
const urlInputRef = ref(null);

// Vuelideate
const rules = {
    url: {
        v$url: helpers.withMessage(
            "Sorry, please check the link and try again.",
            v$url,
        ),
        $lazy: true,
    },
};

const v$ = useVuelidate(rules, { url });

onMounted(() => {
    urlInputRef.value.focus();
});

const isMobile = computed(() => {
    return usePage().props.browser_info.is_mobile;
});

const validCustom = computed(() => {
    // return !!(this.custom.name.length && (!this.custom.url.length || (this.custom.url.length && validUrl.isUri(this.custom.url))));
});

function confirmCustom() {
    emit("confirm-custom", custom);

    // TODO
    // this.$store.dispatch('validateUrl', this.custom.url)
    //   .then(response => {
    //     this.isLoading = false
    //     // Populate the custom object with the validated URL
    //     this.custom.url = response.data.url
    //     this.$emit('confirm-custom', this.custom)
    //   })
    //   .catch(error => {
    //     this.isLoading = false
    //     if (error.response) {
    //       this.urlError = true
    //       switch (error.response.status) {
    //         // URL validation failed.
    //         case 422:
    //           this.urlErrorMessage = error.response.data.url[0]
    //           break;
    //         default:
    //           this.urlErrorMessage = 'There was an error. Please try again.'
    //           break;
    //       }
    //     }
    //   });
}

const parseUrl = async () => {
    const isFormValid = await unref(v$).$validate();

    if (!isFormValid) {
        return;
    }

    isLoading.value = true;

    // API call
    axios
        .post(route("parse"), { url: url.value })
        .then((response) => {
            const product = response.data?.product;

            console.log(product);

            isLoading.value = false;
            // Populate the custom object with the validated URL
            url.value = product?.url;

            emit("url-parsed", product);
        })
        .catch((error) => {
            console.log(error);

            isLoading.value = false;

            if (error?.response) {
                urlError.value = true;

                switch (error.response?.status) {
                    // URL validation failed.
                    case 422:
                        urlErrorMessage.value = error.response.data.url[0];
                        break;
                    default:
                        /**
                         * Diffbot parsing has failed, so let's do the following:
                         * - Set `custom.url` to the URL the user pasted in
                         * - Set `custom.parseError` to true, this will trigger the error message to display when creating the custom gift
                         * - Commit the error message to the Vuex.api store
                         * - Finally, emit the `confirm-custom` event
                         */
                        custom.url = url;

                        // TODO: Convert to Pinia store
                        // this.$store.commit('api/setErrors', [`We tried our very best. Unfortunately, we were unable to find as much information about your gift as we would have liked. Please complete the fields below to create a custom gift.`]);

                        emit("confirm-custom", custom);

                        break;
                }
            } else {
                //
                window.location = "/error-saving-gift";
            }
        });

    return;
};

function scrollToErrors() {
    console.log(this.$refs);
    this.$nextTick(function () {
        // @todo Sometimes this.$refs isn't bound yet, not 100% sure why.
        if (this.$refs.hasOwnProperty("errors")) {
            this.$scrollTo(this.$refs.errors.$el, 300, {
                container: ".dialog__content",
                offset: -20,
            });
        }
    });
}
</script>
<template>
    <Modalog :has-header="true" :has-inline-errors="true" size="medium">
        <template #title>Add a Gift</template>
        <template #content>
            <h2 class="center-align" v-if="isLoading">Fetching Your Gift</h2>
            <template v-else>
                <div class="content-errors" v-if="hasApiErrors">
                    <errors
                        class-modifier="error--wide error--margin-bottom"
                        ref="errors"
                    ></errors>
                </div>
                <div class="dialog__tabs">
                    <!--Create Gift from URL-->
                    <input
                        type="radio"
                        id="create-item-url"
                        class="tab__radio"
                        name="type"
                        value="url"
                        v-model="type"
                    />
                    <label
                        for="create-item-url"
                        class="tab__label tab__label--first"
                        ><span class="text">Copy/Paste URL</span></label
                    >
                    <div class="tab__content" v-if="!isLoading">
                        <div style="padding-top: 0.5rem">
                            <div
                                class="dialog__content--row"
                                :class="{ 'is-error': v$.url.$errors.length }"
                            >
                                <form @submit.prevent>
                                    <label class="form-label" for="item-url"
                                        >Paste a Link from Anywhere on the
                                        Web</label
                                    >
                                    <input
                                        class="input"
                                        type="url"
                                        name="item-url"
                                        placeholder="https://"
                                        autocomplete="off"
                                        :minlength="minInputLength"
                                        v-model="url"
                                        autofocus
                                        ref="urlInputRef"
                                    />
                                </form>
                            </div>
                            <!--URL validation errors-->
                            <div
                                class="dialog__content--row dialog__content--row-no-padding is-error"
                                v-if="v$.url.$errors.length"
                            >
                                <div
                                    class="form-control__response"
                                    v-for="error of v$.url.$errors"
                                    :key="error.$uid"
                                >
                                    {{ error.$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Create Custom Gift-->
                    <input
                        type="radio"
                        id="create-item-custom"
                        class="tab__radio"
                        name="type"
                        value="manual"
                        v-model="type"
                    />
                    <label
                        for="create-item-custom"
                        class="tab__label tab__label--last"
                        ><span class="text">Create a Gift</span></label
                    >
                    <div class="tab__content" v-if="!isLoading">
                        <div style="padding-top: 0.5rem">
                            <div class="dialog__content--row">
                                <label class="form-label" for="custom-name"
                                    >Give Your Gift a Name</label
                                >
                                <input
                                    class="input"
                                    type="text"
                                    name="custom-name"
                                    placeholder="Tickets to a Music Festival"
                                    v-model="custom.name"
                                    autocomplete="off"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!--Desktop-only browser extension callout-->
                <div
                    class="whitespace-top-small"
                    v-if="!isMobile && type === 'url'"
                >
                    <aside class="hint-panel hint-panel__quick-tip">
                        <i class="icon"><icon-hint /></i>
                        <div class="content">
                            <p>
                                For a super easy way to add gifts, get the
                                <a
                                    href="/browser-plugins"
                                    title="Get the Gift Hero browser extension."
                                    >Gift Hero browser extension</a
                                >.
                            </p>
                        </div>
                    </aside>
                </div>
                <!--Mobile-only mobile app callout-->
                <div
                    class="whitespace-top-small"
                    v-if="isMobile && type === 'url'"
                >
                    <aside class="hint-panel hint-panel__quick-tip">
                        <i class="icon icon--mobile-app-callout"
                            ><svg-mobile-app-callouut
                        /></i>
                        <div class="content">
                            <p>
                                Take your wish-listing on the go with Gift
                                Hero's free app.
                                <a
                                    href="/mobile"
                                    title="Get the Gift Hero mobile app for iOS and Android."
                                    >Get it now!</a
                                >.
                            </p>
                        </div>
                    </aside>
                </div>
                <div class="whitespace-top-small" v-else-if="type === 'manual'">
                    <aside class="hint-panel hint-panel__quick-tip">
                        <i class="icon icon--large"><icon-gift-card /></i>
                        <div class="content">
                            <p>
                                <strong>Not sure what you want?</strong
                                ><br />Check out our
                                <a
                                    :href="route('gift-cards')"
                                    title="Check out our Gift Cards!"
                                    >Gift Cards</a
                                >!
                            </p>
                        </div>
                    </aside>
                </div>
            </template>
        </template>
        <template #footer>
            <Throbber v-if="isLoading" />
            <template v-else>
                <button
                    class="strip-btn dialog__action-button"
                    @click="closeModalog"
                    v-if="!isLoading"
                >
                    Cancel
                </button>
                <template v-if="type === 'url'">
                    <button
                        class="strip-btn dialog__action-button dialog__action-button--primary"
                        :disabled="isEmpty(url)"
                        @click="parseUrl"
                    >
                        Next
                    </button>
                </template>
                <template v-if="type === 'manual'">
                    <button
                        class="strip-btn dialog__action-button dialog__action-button--primary"
                        :disabled="custom.name === '' || isLoading"
                        @click="confirmCustom()"
                    >
                        Next
                    </button>
                </template>
            </template>
        </template>
    </Modalog>
</template>
