/**
 * Generate the URLs needed to support user background images on wish lists. Use in scoped style tag
 * @param uuid
 * @note The `uuid` will be the name of the file in Cloudinary and will be in this format:
 * ugc/lists/masthead/2db34b40-8444-43f3-a983-25f99368a330
 */
export function useMastheadImageUrls(uuid) {
    // Setup the Cloudinary transformation URLs manually.
    return {
        small1x: `url(https://res.cloudinary.com/gift-hero/image/upload/dpr_1.0,c_lfill,h_240,w_600,g_auto,q_auto:good/${uuid}.jpg)`,
        small2x: `url(https://res.cloudinary.com/gift-hero/image/upload/dpr_2.0,c_lfill,h_240,w_600,g_auto,q_auto:good/${uuid}.jpg)`,
        large1x: `url(https://res.cloudinary.com/gift-hero/image/upload/dpr_1.0,c_lfill,h_280,w_1044,g_auto,q_auto:good/${uuid}.jpg)`,
        large2x: `url(https://res.cloudinary.com/gift-hero/image/upload/dpr_2.0,c_lfill,h_280,w_1044,g_auto,q_auto:good/${uuid}.jpg)`,
    };
}
