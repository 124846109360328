<script setup>
import { computed, ref } from "vue";
import IconHint from "~/svg/icon-hint.svg";
import { usePage } from "@inertiajs/vue3";
import { useModalogStore } from "@/stores/modalog";
import { useWishlistStore } from "@/stores/wishlist";

const modalogStore = useModalogStore();
const wishlistStore = useWishlistStore();

const props = defineProps({
    list: {
        type: Object,
        default: {},
    },
});

const showConfirmedGifts = ref(false);

const confirmedGifts = computed(() => {
    return usePage().props.items?.data;
});

const spoilWishList = () => {
    props.list.isSpoiled = true;
    wishlistStore.showWishlistItems = true;
    wishlistStore.isSpoiled = true;
    modalogStore.closeModalog();

    /* Only fetch the confirmed gifts once per page load. */
    // if ( this.list.hasOwnProperty('confirmedGifts') ) {
    //   this.$set(this.list, 'isSpoiled', true)
    //   this.$store.dispatch('closeModalog').then()
    // }
    if (confirmedGifts.value.length) {
        // props.list.isSpoiled = true;
        // wishlistStore.showWishlistItems = true;
        // modalogStore.closeModalog();
    } else {
        // this.$store.dispatch('purchases/getPurchasesForYou', this.list.uuid)
        //     .then((response) => {
        //         this.$set(this.list, 'isSpoiled', true)
        //         this.$set(this.list, 'confirmedGifts', response.data.hasOwnProperty('gifts') ? response.data.gifts : null)
        //         this.$store.commit('wishlists/showConfirmedGifts', true)
        //         this.$store.commit('wishlists/confirmedGifts', response.data.gifts)
        //         this.$store.dispatch('closeModalog').then()
        //     })
    }
};
</script>
<template>
    <modalog :has-header="false" size="medium">
        <template #contentTitle>
            <h2 class="dialog__title dialog__title--no-header">
                Spoiler Alert!
            </h2>
        </template>
        <template #content>
            <p>
                Do you want to see the gifts that have been confirmed for
                <strong>{{ list.name }}</strong
                >?<br />
            </p>
            <!--Tip-->
            <aside
                class="hint-panel hint-panel__quick-tip whitespace-bottom-small"
            >
                <i class="icon"><icon-hint /></i>
                <div class="content">
                    <p>
                        The next time you visit this page your confirmed gifts
                        will be hidden again.
                    </p>
                </div>
            </aside>
        </template>
        <template #cancelButton>
            <Link
                :href="route('list.show', { giftlist: props.list.uuid })"
                class="strip-btn dialog__action-button"
                >Cancel</Link
            >
        </template>
        <template #confirmButton>
            <button
                class="strip-btn dialog__action-button dialog__action-button--primary"
                @click="spoilWishList"
            >
                Show Confirmed
            </button>
        </template>
    </modalog>
</template>
