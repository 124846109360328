<template>
    <Head :title="`Find a Friend's Wish List`" />
    <FriendsLayout>
        <template #sidebar-widescreen>
            <Sidebar />
        </template>
        <template #sidebar-mobile-top>
            <Sidebar is-mobile="true" />
        </template>
        <template #content>
            <!--Mobile Subnav-->
            <!--@include('common.sidebar.friends-mobile')-->
            <!--Find Friends Module-->
            <div class="grid-form">
                <!--Header-->
                <h2 class="grid-header__title">Find Friends</h2>
                <!--Description-->
                <p class="grid-header__subtitle">
                    Search for someone by name, username, or organization.
                </p>
                <!--Errors-->
                <error-hint
                    v-show="showErrors"
                    :error="{
                        message: `Sorry, we couldn’t find a match for you. Please try again, or invite the person you’re looking for to Gift Hero!`,
                    }"
                    @dismiss="showErrors = false"
                ></error-hint>
                <!--Filter Form-->
                <form
                    class="grid-header__search form-material form-material--container whitespace-top-small whitespace-bottom-small"
                    @submit.prevent="submitForm"
                >
                    <div class="form-control__inline-search">
                        <div style="margin-right: 10px">
                            <label for="searchBy">Search By:</label>
                            <select
                                name="searchBy"
                                id="searchBy"
                                v-model="form.searchBy"
                                @change="handleSearchByChange"
                            >
                                <option value="name" selected>Name</option>
                                <option value="username">Username</option>
                                <option value="organization">
                                    Organization
                                </option>
                            </select>
                        </div>
                        <div
                            class="inline-search__input"
                            v-if="form.searchBy === 'name'"
                        >
                            <fieldset
                                style="
                                    display: flex;
                                    flex-direction: row;
                                    width: 100%;
                                "
                            >
                                <div class="inline-search__input">
                                    <label for="firstName">First Name</label>
                                    <input
                                        class="search"
                                        type="search"
                                        name="first_name"
                                        id="firstName"
                                        placeholder="First Name"
                                        autocomplete="off"
                                        autocorrect="off"
                                        autocapitalize="off"
                                        spellcheck="false"
                                        v-model="form.first_name"
                                        autofocus
                                    />
                                </div>
                                <div class="inline-search__input">
                                    <label
                                        for="lastName"
                                        class="inline-search__inline-label"
                                        >Last Name</label
                                    >
                                    <input
                                        class="search"
                                        type="search"
                                        name="last_name"
                                        id="lastName"
                                        placeholder="Last Name"
                                        autocomplete="off"
                                        autocorrect="off"
                                        autocapitalize="off"
                                        spellcheck="false"
                                        v-model="form.last_name"
                                        autofocus
                                    />
                                </div>
                            </fieldset>
                        </div>
                        <div
                            class="inline-search__input"
                            v-else-if="form.searchBy === 'username'"
                        >
                            <label for="username">Username</label>
                            <input
                                class="search"
                                type="search"
                                name="username"
                                id="username"
                                placeholder="Search by Username"
                                autocomplete="off"
                                autocorrect="off"
                                autocapitalize="off"
                                spellcheck="false"
                                v-model="form.username"
                                autofocus
                            />
                        </div>
                        <div
                            class="inline-search__input"
                            v-else-if="form.searchBy === 'organization'"
                        >
                            <label for="organization">Organization</label>
                            <input
                                class="search"
                                type="search"
                                name="organization"
                                id="organization"
                                placeholder="Search by Organization"
                                autocomplete="off"
                                autocorrect="off"
                                autocapitalize="off"
                                spellcheck="false"
                                v-model="form.organization"
                                autofocus
                            />
                        </div>
                        <div class="inline-search__button">
                            <button
                                class="btn-plain form-material__submit"
                                :disabled="!isFormValid"
                                type="submit"
                            >
                                <i class="icon"><icon-magnifying-glass /></i>
                            </button>
                        </div>
                    </div>
                </form>
                <!-- .grid-header__filter -->
                <!--Loading-->
                <aside class="grid-header__loading" v-show="form.processing">
                    <Throbber />
                </aside>
            </div>
            <!-- .grid-header -->
            <!--Find Friends Results-->
            <div
                class="find-friends__results"
                v-show="
                    !isEmpty($page.props.users?.data) &&
                    !form.processing &&
                    !isNewSearch
                "
                ref="searchResultsEl"
            >
                <div class="grid-header whitespace-top-small">
                    <div class="grid-header__title center-align">
                        Search results for "{{ displayedSearchQuery }}"
                    </div>
                </div>
                <!-- .grid-header -->
                <div class="list-grid of-users">
                    <friend-card
                        :user="user"
                        v-for="user of $page.props.users?.data"
                    ></friend-card>
                </div>
                <!-- .list-grid -->
                <!--Pagination-->
                <inertia-pagination
                    :meta="$page.props.users?.meta"
                    :links="$page.props.users?.links"
                    v-if="
                        !isEmpty($page.props.users?.meta) &&
                        !isEmpty($page.props.users?.links)
                    "
                />
            </div>
            <!-- .find-friends__results -->
            <!--TODO: Remove if we really kill invite friends-->
            <!--<div class="whitespace-top-small">-->
            <!--    <aside class="hint-panel hint-panel__quick-tip">-->
            <!--        <i class="icon"><icon-hint /></i>-->
            <!--        <div class="content">-->
            <!--            <p>Not seeing who you’re looking for? <a :href="route('friends.invite-friends')">Invite them</a>!</p>-->
            <!--        </div>-->
            <!--    </aside>-->
            <!--</div>-->
        </template>
    </FriendsLayout>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { router, useForm, usePage } from "@inertiajs/vue3";
import { isEmpty, isNull, omitBy } from "lodash";
import ErrorHint from "@/Components/errors/ErrorHint.vue";
import IconHint from "~/svg/icon-hint.svg";
import IconMagnifyingGlass from "~/svg/magnifying-glass.svg";
import InertiaPagination from "@/Components/Pagination/InertiaPagination.vue";
import FriendsLayout from "@/Layouts/FriendsLayout.vue";
import Sidebar from "@/Pages/Friends/Partials/Sidebar.vue";
import FriendCard from "@/Components/Cards/FriendCard.vue";
import Throbber from "@/Components/Throbber/Throbber.vue";

const props = defineProps({
    user: {
        type: String,
        default: "",
        required: false,
    },
});

const form = useForm({
    first_name: null,
    last_name: null,
    organization: null,
    searchBy: "name",
    username: null,
});

onMounted(() => {
    document.getElementsByTagName("input")[0].focus();
});

const displayedSearchQuery = ref(null);
const heroes = ref([]);
const isLoading = ref(false);
const isNewSearch = ref(false);
const lastQuery = ref("");
const owner = ref(null);
const searchResultsEl = ref(null);
const searchQuery = ref("");
const showErrors = ref(false);

const setDisplayedSearchQuery = () => {
    switch (form.searchBy) {
        case "name":
            return (displayedSearchQuery.value = `${form.first_name} ${form.last_name}`);
        case "username":
            return (displayedSearchQuery.value = form.username);
        case "organization":
            return (displayedSearchQuery.value = form.organization);
    }
};

const isFormValid = computed(() => {
    switch (form.searchBy) {
        case "name":
            return (
                form.first_name?.length > 0 &&
                form.last_name?.length > 0 &&
                !form.processing
            );
        case "username":
            return form.username?.length > 0 && !form.processing;
        case "organization":
            return form.organization?.length > 0 && !form.processing;
    }
});

const hasSearchResults = computed(() => {
    return usePage().props.users?.data?.length > 0;
});

function getSearchText() {
    return form.processing ? "Searching" : "Search";
}

function userUrl(user) {
    return "/" + user.username;
}

function followUser(user) {
    let endpoint = "/api/v1/users/follow/" + user.uuid;
    // make with the api call already
    this.$http.get(endpoint).then(
        (response) => {},
        (response) => {
            // error callback
            console.log(response);
        },
    );
}

function unfollowUser(user) {
    let endpoint = "/api/v1/users/follow/" + user.uuid;
    // make with the api call already
    this.$http.delete(endpoint).then(
        (response) => {},
        (response) => {
            // error callback
            console.log(response);
        },
    );
}

function goToMyProfile(user) {
    window.location = "/lists/" + user.uuid;
}

function followUnfollowUser(user, myUuid) {
    if (this.loggedin) {
        if (user.uuid == myUuid) {
            this.goToMyProfile(user);
        } else {
            if (user.following) {
                user.following = false;
                this.unfollowUser(user);
            } else {
                user.following = true;
                this.followUser(user);
            }
        }
    } else {
        window.location = "/login";
    }
}

const handleSearchByChange = () => {
    // This hides the search results when the `searchBy` changes
    isNewSearch.value = true;

    // Hide errors
    showErrors.value = false;

    // This is a hack to reset the form values when the `searchBy` changes
    ["first_name", "last_name", "organization", "username"].forEach(
        (key) => (form[key] = null),
    );
    form.defaults();
    form.reset("first_name", "last_name", "organization", "username");

    // Focus the first form input
    setTimeout(() => {
        document.getElementsByTagName("input")[0].focus();
    }, 0);
};

function submitForm() {
    console.log("Submitting form...");
    isNewSearch.value = false;
    setDisplayedSearchQuery();

    form.transform((data) => {
        return omitBy(data, isNull);
    }).post(route("friends.search"), {
        preserveScroll: true,
        preserveState: true,
        replace: true,
        onSuccess: () => {
            showErrors.value = !hasSearchResults.value;
        },
    });
}
</script>
