<template>
    <Modalog :has-header="true">
        <template #title>Unfollow {{ user.full_name }}</template>
        <template #content>
            <p>
                Please confirm you no longer wish to follow
                <strong>{{ user.full_name }} (@{{ user.username }})</strong>
            </p>
        </template>
        <template #confirmButton>
            <button
                class="strip-btn dialog__action-button dialog__action-button--warning"
                @click="confirmUnfollowUser"
                :disabled="isSubmitting"
            >
                Unfollow
            </button>
        </template>
    </Modalog>
</template>
<script setup>
import { computed, ref } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import Modalog from "@/Components/Modalogs/Modalog.vue";
import { useModalogStore } from "@/stores/modalog";
import { useApiService } from "@/services/useApiService";

// Props
const props = defineProps({
    user: {
        type: Object,
        default: function () {
            return {};
        },
    },
});

// Services
const { unfollowUser } = useApiService();

// Composables
const { closeModalog } = useModalogStore();

// State
const isSubmitting = ref(false);

// Computed
const isMyFollowersView = computed(() => {
    return ["Friends/Following", "Friends/Followers"].includes(
        usePage().component,
    );
});

// Methods
function confirmUnfollowUser() {
    isSubmitting.value = true;

    unfollowUser(props.user.uuid).then(() => {
        isSubmitting.value = false;
        closeModalog();
        if (usePage().component === "Friends/FindFriends") {
            props.user.following = false;
        } else if (isMyFollowersView.value) {
            props.user.is_processing = true;
            props.user.is_processing_text = `Unfollowed @${props.user.username}`;
            router.reload({ only: ["following", "followers"] });
        } else {
            router.reload({ only: ["following", "followers", "users"] });
        }
    });
}
</script>
