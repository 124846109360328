/**
 * Utilities for generating URL's.
 */
export default {
    methods: {
        bookmarksUrl() {
            return `/bookmarks`;
        },
        giftsByYouUrl() {
            return `/gifts-confirmed/by-you`;
        },
        giftsForYouUrl() {
            return `/gifts-confirmed/for-you`;
        },
        itemUrl(itemUuid) {
            return `/items/${itemUuid}`;
        },
        listUrl(listUuid) {
            return `/list/${listUuid}`;
        },
        userFollowers(userUuid) {
            return `/users/followers/${userUuid}`;
        },
        userFollowing(userUuid) {
            return `/users/following/${userUuid}`;
        },
        userUrl(username) {
            return `/${username}`;
        },
    },
};
