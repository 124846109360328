<script setup>
import ShowHide from "../../Components/ShowHide/ShowHide.vue";
const props = defineProps({
    bio: Object,
});
</script>

<template>
    <show-hide
        :bio="bio"
        :first-name="props.bio.firstName"
        :last-name="props.bio.lastName"
    />
</template>
