import { computed, ref, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import { sortBy } from "lodash";

export function useFriendsView(isFollowers = false) {
    // State
    const errorMessage = ref("");
    const filterType = ref("recent");
    const friends = ref(
        isFollowers
            ? usePage().props.followers.data
            : usePage().props.following.data,
    );
    const friendsRecent = ref([...friends.value]);
    const isOwner =
        usePage().props.auth.user?.data.uuid ===
        usePage().props.owner?.data.uuid;
    const query = ref("");
    const searchQuery = ref("");

    /////////////////////////////////////////////////////
    // Computed
    /////////////////////////////////////////////////////
    /* Is this my list of followers? */
    const isMine = computed(() => {
        return props.myUuid === props.userUuid;
    });

    /* Ability to sort users by `filterType` set via the `sortUsersBy` method. */
    const sortedFriends = computed(() => {
        switch (filterType.value) {
            case "alpha":
                return sortFriendsAlpha.value;
                break;
            case "recent":
                return friends.value;
                break;
            default:
                return friends.value;
                break;
        }
    });

    const sortFriendsAlpha = computed(() => {
        return sortBy(friends.value, [
            (user) => user.presentation_name.toLowerCase(),
        ]);
    });

    /////////////////////////////////////////////////////
    // Methods
    /////////////////////////////////////////////////////
    function userUrl(user) {
        return "/" + user.username;
    }

    function searchPlaceholder() {
        return "Search";
        // return 'Search ' + owner.first_name + '\'s followers';
    }

    function userListPlural(count) {
        return count == 1 ? "Wish List" : "Wish Lists";
    }

    function updateSearchQuery() {
        errorMessage.value =
            "We couldn't find anyone who matched your search. Try again?";
        searchQuery.value = query.value.toLowerCase();
    }

    function followUser(user) {
        return axios.post(route("user.follow", { uuid: user.uuid }), {});
    }

    function unfollowUser(user) {
        return axios.delete(route("user.unfollow", { uuid: user.uuid }), {});
    }

    // TODO: Inertia
    function followUnfollowUser(user) {
        if (user.following) {
            user.following = false;
            this.unfollowUser(user);
        } else {
            user.following = true;
            this.followUser(user);
        }
    }

    /**
     * Watch `$page.props` for changes and update state
     */
    watch(
        () => usePage().props.following,
        (newFollowing) => {
            if (newFollowing) {
                friends.value = newFollowing.data;
            }
        },
    );

    watch(
        () => usePage().props.followers,
        (newFollowers) => {
            if (newFollowers) {
                friends.value = newFollowers.data;
            }
        },
    );

    return {
        filterType,
        followUnfollowUser,
        followUser,
        friends,
        friendsRecent,
        isOwner,
        query,
        searchPlaceholder,
        searchQuery,
        sortFriendsAlpha,
        sortedFriends,
        updateSearchQuery,
        unfollowUser,
        userListPlural,
        userUrl,
    };
}
