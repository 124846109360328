<script setup>
import { usePage } from "@inertiajs/vue3";
import ListCard from "../../Components/cards/ListCard.vue";
import GiftHeroes from "../../Components/Explore/GiftHeroes.vue";
import Throbber from "@/Components/Throbber/Throbber.vue";
const props = usePage().props;
const { expert_lists, experts } = props;
</script>

<template>
    <Head>
        <title>Gift Ideas for Birthdays, Weddings & More | Gift Hero</title>
        <meta
            name="description"
            content="Browse thousands of gift ideas for all ages and occasions to find the perfect gift for that special someone from our hand crafted gift lists!"
        />
        <meta
            property="og:title"
            content="Gift Ideas for Birthdays, Weddings & More | Gift Hero"
        />
        <meta
            property="og:description"
            content="Browse thousands of gift ideas for all ages and occasions to find the perfect gift for that special someone from our hand crafted gift lists!"
        />
        <link rel="canonical" :href="route('explore')" />
        <meta property="og:url" :content="route('explore')" />
        <meta
            property="og:image"
            content="https://res.cloudinary.com/gift-hero/image/upload/v1685819255/lib/img/meta/og-image-1200x630-1.png"
        />
    </Head>

    <main class="main" role="main" id="app">
        <div class="main__container stacked-children whitespace-bottom-large">
            <div
                class="grid-section whitespace-top"
                ref="scrollToElement"
                key="headerWishLists"
            >
                <div class="grid-header__title">
                    Gift Lists from our Experts.
                </div>
                <div class="grid-header__description">
                    <p>
                        When you need gift ideas, check out these lists created
                        by our Experts. Each wish list is a collection of the
                        most unique, cool and thoughtful gifts around.
                    </p>
                </div>
            </div>
            <!-- .grid-section -->
            <div
                class="list-grid list-grid--centered"
                key="wishLists"
                v-if="expert_lists.data.length"
            >
                <list-card
                    v-for="list in expert_lists.data"
                    :list="list"
                    :key="list.uuid"
                ></list-card>
            </div>
        </div>

        <!-- Expert lists-->

        <div class="grid-section whitespace-top" key="giftHeroExperts">
            <section class="explore__experts">
                <h2 class="grid-header__title">
                    Find inspiration from our Experts.
                </h2>
                <div class="grid-header__description">
                    <p>
                        When you don’t know what to add to your wish list or buy
                        for a friend, they’ll help you choose a great gift!
                    </p>
                </div>

                <gift-heroes
                    v-if="experts.data.length"
                    :experts="experts.data"
                ></gift-heroes>
                <throbber key="throbberGiftHeroUsers" v-else></throbber>
            </section>
        </div>
    </main>
</template>

<style lang="scss" scoped>
@use "../../../scss/landing/explore.scss";
</style>
