<template>
    <button
        class="popover__option strip-btn is-danger"
        @click="openDeleteListModalog"
    >
        <span class="icon"><icon-option-delete /></span>
        <span class="label">Delete {{ listTypeMoniker }} List</span>
    </button>
</template>
<script setup>
import { computed } from "vue";
import { useModalogStore } from "@/stores/modalog";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import IconOptionDelete from "~/svg/ui-option-delete.svg";

// Pinia Stores
const cardOptionsMenuStore = useCardOptionsMenuStore();
const modalogStore = useModalogStore();

// Props
const props = defineProps({
    list: {
        type: Object,
        default: {},
    },
});

// Computed
const listTypeMoniker = computed(() => {
    return (
        props.list.type.charAt(0).toUpperCase() + props.list.type.substring(1)
    );
});

// Methods
// TODO
function openDeleteListModalog() {
    modalogStore.showModalog({
        type: "ModalogDeleteWishList",
        props: {
            list: props.list,
        },
    });

    cardOptionsMenuStore.closeAll();
}
</script>
