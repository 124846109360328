import { ref } from "vue";
import { useAPIForm } from "@/composables/useAPIForm";
import { useModalogStore } from "@/stores/modalog";

export function useImageUpload() {
    const file = ref(null);
    const cloudinaryConfig = ref(null);
    const cloudinaryEndpoint =
        "https://api.cloudinary.com/v1_1/gift-hero/image/upload";
    const dropzoneRef = ref(null);
    const modalogStore = useModalogStore();

    const form = useAPIForm({
        imageUuid: null,
        public_id: null,
        asset_id: null,
    });

    const cancel = () => {
        modalogStore.closeModalog();
        resetDropzone();
    };

    const fetchUploadSignature = (photo_type = null) => {
        if (!photo_type) {
            console.error("Photo type is required");
        }

        return axios
            .get(route("uploads.signature", { photo_type }))
            .then((response) => {
                cloudinaryConfig.value = response.data;
                return response.data;
            });
    };

    const imageAdded = (addedFile) => {
        file.value = addedFile;
        document.querySelector(".dz-progress").style.display = "none";
    };

    const imageUploaded = (imageUuid) => {
        form.imageUuid = imageUuid;
        modalogStore.isConfirmDisabled = false;
    };

    const resetDropzone = () => {
        dropzoneRef.value.removeAllFiles();
    };

    const uploadImage = async () => {
        const formData = new FormData();
        formData.append("file", file.value); // The file Dropzone
        formData.append("api_key", cloudinaryConfig.value.api_key);
        formData.append("signature", cloudinaryConfig.value.signature);
        Object.entries(cloudinaryConfig.value.options).forEach(
            ([key, value]) => {
                formData.append(key, value);
            },
        );

        return fetch(cloudinaryEndpoint, {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                const responseData = JSON.parse(data);
                return responseData;
            });
    };

    return {
        cancel,
        cloudinaryEndpoint,
        dropzoneRef,
        fetchUploadSignature,
        file,
        form,
        imageAdded,
        imageUploaded,
        resetDropzone,
        uploadImage,
    };
}
