<template>
    <Head :title="wishlistName">
        <meta name="description" :content="pageDescription" />
        <meta property="og:title" :content="ogTitle" />
        <meta property="og:description" :content="ogDescription" />
        <meta property="og:url" :content="ogUrl" />
        <meta property="og:image" :content="ogImage" />
        <link rel="canonical" :href="ogUrl" />
        <meta v-if="noIndex" name="robots" content="noindex, follow" />
    </Head>
    <AppLayout>
        <SidebarLayout>
            <template #sidebar-widescreen>
                <BackToWishLists />
                <slot name="sidebar"></slot>
            </template>
            <template #content>
                <!-- MastheadVisual-->
                <MastheadVisual />
                <!-- Sorting-->
                <!--TODO: Extract to SFC-->
                <!--List Controls-->
                <div
                    class="grid-header__filter"
                    v-if="route().current() === 'list.show'"
                >
                    <!--List sorting-->
                    <template v-if="showSortFilters">
                        <div class="select is-transparent has-icon icon-subtle">
                            <i class="icon"><svg-sort></svg-sort></i>
                            <select
                                name="orderBy"
                                v-model="sortSelected"
                                title="Sort gifts by"
                                :disabled="isLoading"
                            >
                                <option
                                    v-for="option in sortOptions"
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                    </template>
                </div>
                <section class="list-grids">
                    <template
                        v-if="
                            route().current() === 'list.confirmed' &&
                            wishlistStore.isSpoiled
                        "
                    >
                        <div class="list-reveal">
                            <header class="list-reveal__header">
                                <h2 class="list-reveal__title">
                                    <strong>{{
                                        $page.props.totalConfirmed
                                    }}</strong>
                                    Confirmed Gifts
                                </h2>
                                <list-spoiler-button
                                    :is-standalone="true"
                                    :list="giftlist"
                                ></list-spoiler-button>
                            </header>
                            <div
                                class="list-grid"
                                ref="listContainer"
                                key="listGrid"
                            >
                                <slot name="list-grid-start"></slot>
                                <template
                                    v-for="item of $page.props.items?.data"
                                    :key="item.uuid"
                                    v-if="$page.props.items?.data.length > 0"
                                >
                                    <component
                                        :is="ItemCard"
                                        :initial-item="item"
                                        :has-lists="true"
                                        :item="item"
                                        :list="$page.props.giftlist?.data"
                                        :is-owner="
                                            $page.props.giftlist?.data.is_owner
                                        "
                                    />
                                </template>
                                <template v-else>
                                    <p class="list-reveal__sorry">
                                        No gifts have been purchased from this
                                        wish list yet. Why not share your wish
                                        list?
                                    </p>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template v-if="route().current() === 'list.show'">
                        <div
                            class="list-grid"
                            ref="listContainer"
                            key="listGrid"
                        >
                            <slot name="list-grid-start"></slot>
                            <template
                                v-for="item of $page.props.items?.data"
                                :key="item.uuid"
                            >
                                <component
                                    :is="ItemCard"
                                    :initial-item="item"
                                    :has-lists="true"
                                    :item="item"
                                    :list="$page.props.giftlist?.data"
                                    :is-owner="
                                        $page.props.giftlist?.data.is_owner
                                    "
                                />
                            </template>
                        </div>
                        <InertiaPagination
                            :links="$page.props.items.links"
                            :meta="$page.props.items.meta"
                        />
                    </template>
                    <slot name="list-grids-end"></slot>
                </section>
            </template>
        </SidebarLayout>
    </AppLayout>
</template>
<script setup>
import { computed, inject, onMounted, ref, watch } from "vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import SidebarLayout from "@/Layouts/SidebarLayout.vue";
import BackToWishLists from "@/Components/WishList/BackToWishLists.vue";
import ItemCard from "@/Components/Cards/Item/ItemCard.vue";
import ListSpoilerButton from "@/Components/Actions/ListSpoilerButton.vue";
import MastheadVisual from "@/Components/WishList/MastheadVisual.vue";
import { useAuthUser } from "@/composables/useAuthUser";
import { useWishList } from "@/composables/useWishList";
import SvgSort from "~/svg/ui-sort.svg";
import { router, usePage } from "@inertiajs/vue3";
import { useWishlistStore } from "@/stores/wishlist";
import InertiaPagination from "@/Components/Pagination/InertiaPagination.vue";

const wishlistStore = useWishlistStore();

defineOptions({
    layout: [BaseLayout],
});

const { giftlist, meta } = useWishList();
const owner = usePage().props.owner?.data;
const route = inject("route");
const user = useAuthUser();
const isLoading = ref(false);

const sortOptions = ref([
    {
        value: { orderBy: "sequence", sortDirection: "desc" },
        text: "Gifts: New → Old",
    },
    {
        value: { orderBy: "created_at", sortDirection: "asc" },
        text: "Gifts: Old → New",
    },
    {
        value: { orderBy: "price", sortDirection: "asc" },
        text: "Price: Low → High",
    },
    {
        value: { orderBy: "price", sortDirection: "desc" },
        text: "Price: High → Low",
    },
]);
const selectedSortOption = computed(() => {
    if (!usePage().props.sortOption) {
        return null;
    }

    sortOptions.value.findIndex(
        (obj) =>
            obj.value.orderBy === usePage().props.sortOption.orderBy &&
            obj.value.sortDirection ===
                usePage().props.sortOption.sortDirection,
    );
});

const sortSelected = ref(
    selectedSortOption.value
        ? sortOptions.value[selectedSortOption].value
        : sortOptions.value[0].value,
);

onMounted(() => {
    if (route().current() === "list.confirmed") {
        wishlistStore.showWishlistItems = false;
    } else {
        wishlistStore.showWishlistItems = true;
    }
});

const ownerName = owner.presentation_name;
const openGraphData = usePage().props.giftlist?.data.open_graph;
const wishlistName = computed(() => giftlist.value.name);

// Meta Tags
const pageDescription = `${ownerName}'s &quot;${wishlistName.value}&quot; Wish List on Gift Hero`;
const ogTitle = `${ownerName}'s &quot;${wishlistName.value}&quot; Wish List`;
const ogDescription = computed(
    () =>
        `Give ${ownerName} the perfect gift from their &quot;${wishlistName.value}&quot; wish list`,
);
const ogUrl = openGraphData.url;
const ogImage = openGraphData.share_image;
const noIndex = computed(() => {
    return (
        usePage().props.giftlist?.data.privacy === "private" ||
        (!owner.is_gift_hero && !owner.is_organization)
    );
});

const showSortFilters = computed(() => {
    return usePage().props.items?.meta?.total >= 2 && !wishlistStore.isSpoiled;
});

watch(sortSelected, (newValue) => {
    isLoading.value = true;
    router.reload({
        data: { ...newValue, page: 1 },
        onFinish: () => {
            isLoading.value = false;
        },
        only: ["items"],
        preserveScroll: true,
        preserveState: true,
        replace: false,
        showProgress: true,
    });
});
</script>
