<script setup>
import { identity, pickBy } from "lodash";
import {
    alphaDash,
    instagramUsername,
    twitterUsername,
} from "@/utilities/validations";
import Errors from "@/Components/errors/Errors.vue";
import SvgIconHint from "~/svg/icon-hint.svg";
import SvgSocialFacebook from "~/svg/social-facebook.svg";
import SvgSocialInstagram from "~/svg/social-instagram.svg";
import SvgSocialTwitter from "~/svg/social-twitter.svg";
import SvgSocialWebsite from "~/svg/social-website.svg";
import SvgUploadPhoto from "~/svg/ui-option-upload-image.svg";
import Throbber from "@/components/Throbber/Throbber.vue";
import { required, url } from "@vuelidate/validators";
import { computed, ref, unref, watch } from "vue";
import { useDatepicker } from "@/composables/useDatepicker";
import { router, usePage } from "@inertiajs/vue3";
import { useAPIForm } from "@/composables/useAPIForm";
import { useMastheadImageUrls } from "@/composables/useMastheadImageUrls";
import { useModalogStore } from "@/stores/modalog";
import { useVuelidate } from "@vuelidate/core";

const user = usePage().props?.auth.user.data;
const modalogStore = useModalogStore();
const {
    days,
    isValidBirthdate,
    months,
    selectedDay,
    selectedMonth,
    selectedYear,
    yearsForBirthdate,
} = useDatepicker();
const apiErrors = ref();

selectedDay.value = user.birthdate_day ?? "";
selectedMonth.value = user.birthdate_month ?? "";
selectedYear.value = user.birthdate_year ?? "";

const form = useAPIForm({
    birthdate_day: user.birthdate_day ?? "",
    birthdate_month: user.birthdate_month ?? "",
    birthdate_year: user.birthdate_year ?? "",
    description: user.description ?? "",
    first_name: user.first_name ?? "",
    last_name: user.last_name ?? "",
    social_facebook_url: user.social_facebook_url ?? "",
    social_instagram_username: user.social_instagram_username ?? "",
    social_twitter_username: user.social_twitter_username ?? "",
    username: user.username ?? "",
    website_url: user.website_url ?? "",
});

const rules = {
    form: {
        birthdate_day: {},
        birthdate_month: {},
        birthdate_year: {},
        description: {},
        first_name: { required },
        last_name: { required },
        social_facebook_url: { url },
        social_instagram_username: { instagramUsername },
        social_twitter_username: { twitterUsername },
        username: { alphaDash, required },
        website_url: { url },
    },
};

const v$ = useVuelidate(rules, { form });

const isThrobbing = computed(() => {
    return form.processing;
});

// TODO: `useDatepicker` composable
const isUserProfileValid = computed(() => {
    return !v$.$anyDirty && isValidBirthdate.value;
});

const showRemoveBirthdayButton = computed(() => {
    return (
        form.birthdate_day !== "" &&
        form.birthdate_month !== "" &&
        form.birthdate_year !== ""
    );
});

const userCoverPhoto = computed(() => {
    return user.cover_photo;
});

// TODO: `form` instead of `user`?
const userProfile = computed(() => {
    return pick(user, [
        "birthdate_day",
        "birthdate_month",
        "birthdate_year",
        "description",
        "first_name",
        "last_name",
        "social_facebook_url",
        "social_instagram_username",
        "social_twitter_username",
        "username",
        "website_url",
    ]);
});

const cancel = () => {
    modalogStore.closeModalog();
    form.reset();
    form.clearErrors();
};

const clearBirthdate = () => {
    selectedDay.value = "";
    selectedMonth.value = "";
    selectedYear.value = "";
    form.birthdate_month = "";
    form.birthdate_day = "";
    form.birthdate_year = "";
};

const openEditProfilePhotoModalog = () => {
    modalogStore.showModalog({
        type: "ModalogUserProfilePhotoUpload",
        isConfirmDisabled: true,
    });
};

const saveUserProfile = async () => {
    const isFormValid = await unref(v$).$validate();

    if (!isFormValid || !isValidBirthdate) return;

    form.transform((data) => pickBy(data, identity)).put(
        route("profile.update"),
        {
            onSuccess: (response) => {
                modalogStore.closeModalog();
                // TODO: Show we do this on the back-end
                // If username is changed, back-end should redirect to the new username
                if (response.user.username !== user.username) {
                    router.visit(
                        route("user.giftlists", {
                            username: response.user.username,
                        }),
                    );
                } else {
                    router.reload();
                }
            },
            onError: (errors) => {
                console.log(errors);
            },
        },
    );
};

/**
 * Show the user profile header image upload modalog.
 */
const showUserProfileHeaderImageUploadModalog = () => {
    modalogStore.showModalog({
        type: "ModalogUserProfileHeaderImageUpload",
        props: {
            showModalogOnCancel: {
                type: "ModalogEditUserProfile",
                props: {},
                isConfirmDisabled: true,
            },
        },
        isConfirmDisabled: true,
    });
};

watch(
    () => form.birthdate_day,
    (newDay) => {
        selectedDay.value = newDay;
    },
);

watch(
    () => form.birthdate_month,
    (newMonth) => {
        selectedMonth.value = newMonth;
    },
);

watch(
    () => form.birthdate_year,
    (newYear) => {
        selectedYear.value = newYear;
    },
);

watch(selectedDay, (newDay) => {
    form.birthdate_day = newDay;
});

watch(selectedMonth, (newMonth) => {
    form.birthdate_month = newMonth;
});

watch(selectedYear, (newYear) => {
    form.birthdate_year = newYear;
});

watch(
    () => form.errors,
    (newErrors) => {
        if (newErrors && apiErrors.value.$el) {
            setTimeout(() => {
                apiErrors.value.$el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                });
            }, 0);
        }
    },
);

let mastheadImageUrls;
mastheadImageUrls = useMastheadImageUrls(user.cover_photo);
</script>
<template>
    <keep-alive>
        <modalog
            content-class="dialog__content--nopad"
            :is-fullscreen="true"
            :has-inline-errors="true"
            :has-header="false"
        >
            <template #content>
                <!--Profile header-->
                <section class="masthead-visual masthead-visual--is-profile">
                    <div class="masthead-visual__primary">
                        <div class="masthead-visual__primary--layer-upper">
                            <div class="masthead-visual__content">
                                <figure
                                    class="masthead-visual__profile-picture"
                                >
                                    <button
                                        class="masthead-visual__profile-picture--edit"
                                        @click="openEditProfilePhotoModalog"
                                    >
                                        <div class="label">
                                            <span class="wrap">Edit</span>
                                        </div>
                                    </button>
                                    <div
                                        class="masthead-visual__profile-badge"
                                        v-if="user.is_organization"
                                    >
                                        <span
                                            class="profile-user__organization-badge"
                                            >Organization</span
                                        >
                                    </div>
                                    <img
                                        :src="user.avatar['profile_photo']"
                                        :alt="user.presentation_name"
                                    />
                                </figure>
                                <h1 class="masthead-visual__displayname">
                                    {{ user.presentation_name }}
                                </h1>
                                <h2 class="masthead-visual__username">
                                    @{{ user.username }}
                                </h2>
                            </div>
                            <button
                                class="masthead-visual__action-button"
                                @click="showUserProfileHeaderImageUploadModalog"
                            >
                                <i class="icon"
                                    ><svg-upload-photo></svg-upload-photo></i
                                >Change Header Photo
                            </button>
                        </div>
                        <div
                            class="masthead-visual__primary--layer-lower"
                            :class="{ 'is-default': !userCoverPhoto }"
                        ></div>
                    </div>
                </section>
                <!--Form errors-->
                <errors
                    class-modifier="error--wide error--margin-top-large error--margin-auto"
                    :passed-errors="Object.values(form.errors)"
                    ref="apiErrors"
                />
                <!--Edit profile form-->
                <form
                    class="whitespace-top-small whitespace-bottom-small whitespace-left-small whitespace-right-small"
                    v-on:submit.prevent="submit"
                >
                    <!--First Name, Last Name, Username-->
                    <fieldset class="fieldset">
                        <div class="fieldgroup is-2">
                            <!--First name-->
                            <div class="field">
                                <label class="form-label" for="first_name"
                                    >First Name</label
                                >
                                <div class="control">
                                    <input
                                        class="input"
                                        :class="{
                                            'is-invalid':
                                                v$.form.first_name.$error,
                                        }"
                                        type="text"
                                        name="first_name"
                                        spellcheck="false"
                                        placeholder=""
                                        required
                                        v-model="v$.form.first_name.$model"
                                    />
                                </div>
                                <p
                                    class="help is-invalid"
                                    v-if="v$.form.first_name.$error"
                                >
                                    First name is required.
                                </p>
                            </div>
                            <!--Last name-->
                            <div class="field">
                                <label class="form-label" for="last_name"
                                    >Last Name</label
                                >
                                <div class="control">
                                    <input
                                        class="input"
                                        :class="{
                                            'is-invalid':
                                                v$.form.last_name.$error,
                                        }"
                                        type="text"
                                        name="last_name"
                                        spellcheck="false"
                                        placeholder=""
                                        required
                                        v-model="v$.form.last_name.$model"
                                    />
                                </div>
                                <p
                                    class="help is-invalid"
                                    v-if="v$.form.last_name.$error"
                                >
                                    Last name is required.
                                </p>
                            </div>
                        </div>
                        <!--Username-->
                        <div class="field max-50">
                            <label class="form-label" for="username"
                                >Username</label
                            >
                            <div class="control">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group__text"
                                            >gifthero.com/</span
                                        >
                                    </div>
                                    <input
                                        class="input"
                                        :class="{
                                            'is-invalid':
                                                v$.form.username.$error ||
                                                form.errors.username,
                                        }"
                                        type="text"
                                        name="username"
                                        autocapitalize="off"
                                        spellcheck="false"
                                        required
                                        v-model="v$.form.username.$model"
                                    />
                                </div>
                            </div>
                            <p
                                class="help is-invalid"
                                v-if="form.errors.username"
                            >
                                {{ form.errors.username }}
                            </p>
                            <p
                                class="help is-invalid"
                                v-if="v$.form.username.required.$invalid"
                            >
                                Username is required.
                            </p>
                            <p
                                class="help is-invalid"
                                v-if="v$.form.username.alphaDash.$invalid"
                            >
                                Username can contain only letters, numbers,
                                dashes and underscores.
                            </p>
                        </div>
                        <div class="field" v-if="!v$.form.username.$error">
                            <p class="help">
                                Your profile web address
                                {{
                                    form.username === user.username
                                        ? "is"
                                        : "will be"
                                }}
                                <strong
                                    >https://www.gifthero.com/{{
                                        form.username
                                    }}</strong
                                >
                            </p>
                        </div>
                        <div class="field">
                            <br />
                            <aside class="hint-panel hint-panel__quick-tip">
                                <i class="icon"
                                    ><svg-icon-hint></svg-icon-hint
                                ></i>
                                <div class="content">
                                    <p v-if="user.is_organization">
                                        Other users can search for you by
                                        username, your full name, or
                                        <a
                                            href="/user/profile"
                                            title="Organization"
                                            >organization&nbsp;name</a
                                        >.
                                    </p>
                                    <p v-else>
                                        Other users can search for you by
                                        username or your full name.
                                    </p>
                                </div>
                            </aside>
                        </div>
                    </fieldset>
                    <!--Birthdate-->
                    <fieldset class="fieldset">
                        <!--Birthdate-->
                        <div class="field">
                            <label
                                class="form-label is-optional"
                                for="birthdate"
                                >Birthdate</label
                            >
                            <div class="control">
                                <!--Birthdate month-->
                                <div class="select mr-1">
                                    <select
                                        class="select"
                                        name="birthdate_month"
                                        id=""
                                        v-model="v$.form.birthdate_month.$model"
                                    >
                                        <option value="">Month</option>
                                        <option
                                            :value="month.val"
                                            v-for="month of months()"
                                            :key="month.val"
                                        >
                                            {{ month.text }}
                                        </option>
                                    </select>
                                </div>
                                <!--Birthdate day-->
                                <div class="select mr-1">
                                    <select
                                        name="birthdate_day"
                                        class="select"
                                        v-model="v$.form.birthdate_day.$model"
                                    >
                                        <option value="">Day</option>
                                        <option
                                            :value="day"
                                            v-for="day of days()"
                                            :key="day"
                                        >
                                            {{ day }}
                                        </option>
                                    </select>
                                </div>
                                <!--Birthdate year-->
                                <div class="select mr-1">
                                    <select
                                        name="birthdate_year"
                                        class="select"
                                        v-model="v$.form.birthdate_year.$model"
                                    >
                                        <option value="">Year</option>
                                        <option
                                            :value="year"
                                            v-for="year in yearsForBirthdate"
                                            :key="year"
                                        >
                                            {{ year }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <p class="help">
                                Only the month and day are visible to others.
                            </p>
                            <p>
                                <a
                                    href="javascript:void(0)"
                                    class="button is-primary is-rounded is-teensy is-inverted has-shadow"
                                    @click.prevent="clearBirthdate"
                                    v-if="showRemoveBirthdayButton"
                                    >Remove Birthday</a
                                >
                            </p>
                            <p class="help is-invalid" v-if="!isValidBirthdate">
                                Please enter a valid birthdate.
                            </p>
                            <!--<p class="help is-invalid" v-if="apiErrors.hasOwnProperty('birthdate_day')">{{ apiErrors.birthdate_day[0] }}</p>-->
                            <!--<p class="help is-invalid" v-if="apiErrors.hasOwnProperty('birthdate_month')">{{ apiErrors.birthdate_month[0] }}</p>-->
                            <!--<p class="help is-invalid" v-if="apiErrors.hasOwnProperty('birthdate_year')">{{ apiErrors.birthdate_year[0] }}</p>-->
                        </div>
                    </fieldset>
                    <!--Description-->
                    <fieldset class="fieldset">
                        <!--Bio-->
                        <div class="field">
                            <label
                                for="description"
                                class="form-label is-optional"
                                >Bio</label
                            >
                            <div class="control">
                                <textarea
                                    class="textarea"
                                    name="description"
                                    rows="3"
                                    placeholder="A little bit about yourself"
                                    v-model="v$.form.description.$model"
                                ></textarea>
                            </div>
                        </div>
                    </fieldset>
                    <!--Website, Instagram, Facebook, Twitter-->
                    <fieldset class="fieldset">
                        <legend class="legend">Other places to find you</legend>
                        <div class="fieldgroup is-2">
                            <!--Website-->
                            <div class="field">
                                <label
                                    for="website_url"
                                    class="form-label is-optional"
                                    >Website</label
                                >
                                <div class="control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <i class="icon"
                                                ><svg-social-website></svg-social-website
                                            ></i>
                                        </div>
                                        <input
                                            type="text"
                                            name="website_url"
                                            class="input"
                                            placeholder="https://example.com"
                                            autocapitalize="off"
                                            spellcheck="false"
                                            v-model="v$.form.website_url.$model"
                                        />
                                    </div>
                                </div>
                                <p
                                    class="help is-invalid"
                                    v-if="v$.form.website_url.$error"
                                >
                                    Please enter a valid website address.
                                </p>
                            </div>
                            <!--Instagram-->
                            <div class="field">
                                <label
                                    for="social_instagram_username"
                                    class="form-label is-optional"
                                    >Instagram Username</label
                                >
                                <div class="control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <i class="icon"
                                                ><svg-social-instagram></svg-social-instagram
                                            ></i>
                                        </div>
                                        <input
                                            type="text"
                                            name="social_instagram_username"
                                            class="input"
                                            placeholder="@username"
                                            autocapitalize="off"
                                            autocorrect="off"
                                            spellcheck="false"
                                            v-model="
                                                v$.form
                                                    .social_instagram_username
                                                    .$model
                                            "
                                        />
                                    </div>
                                </div>
                                <p
                                    class="help is-invalid"
                                    v-if="
                                        v$.form.social_instagram_username.$error
                                    "
                                >
                                    Instagram usernames can contain only
                                    letters, numbers, periods and underscores
                                    and must be 30 characters or less.
                                </p>
                            </div>
                        </div>
                        <div class="fieldgroup is-2">
                            <!--Facebook-->
                            <div class="field">
                                <label
                                    for="social_facebook_url"
                                    class="form-label is-optional"
                                    >Facebook URL</label
                                >
                                <div class="control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <i class="icon"
                                                ><svg-social-facebook></svg-social-facebook
                                            ></i>
                                        </div>
                                        <input
                                            type="text"
                                            name="social_facebook_url"
                                            class="input"
                                            placeholder="https://facebook.com"
                                            autocapitalize="off"
                                            autocorrect="off"
                                            spellcheck="false"
                                            v-model="
                                                v$.form.social_facebook_url
                                                    .$model
                                            "
                                        />
                                    </div>
                                </div>
                                <p
                                    class="help is-invalid"
                                    v-if="v$.form.social_facebook_url.$error"
                                >
                                    Please enter a valid Facebook website
                                    address.
                                </p>
                            </div>
                            <!--Twitter-->
                            <div class="field">
                                <label
                                    for="social_twitter_username"
                                    class="form-label is-optional"
                                    >Twitter Username</label
                                >
                                <div class="control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <i class="icon"
                                                ><svg-social-twitter></svg-social-twitter
                                            ></i>
                                        </div>
                                        <input
                                            type="text"
                                            name="social_twitter_username"
                                            class="input"
                                            placeholder="@username"
                                            autocapitalize="off"
                                            autocorrect="off"
                                            spellcheck="false"
                                            v-model="
                                                v$.form.social_twitter_username
                                                    .$model
                                            "
                                        />
                                    </div>
                                </div>
                                <p
                                    class="help is-invalid"
                                    v-if="
                                        v$.form.social_twitter_username.$error
                                    "
                                >
                                    Twitter usernames can contain only letters,
                                    numbers and underscores and must be 15
                                    characters or less.
                                </p>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </template>
            <template #footer>
                <template v-if="form.processing">
                    <throbber></throbber>
                </template>
                <template v-else>
                    <button class="button is-white" @click="cancel">
                        Cancel
                    </button>
                    <button
                        class="button is-primary"
                        :disabled="!isUserProfileValid"
                        @click="saveUserProfile"
                    >
                        Update
                    </button>
                </template>
            </template>
        </modalog>
    </keep-alive>
</template>
<style scoped>
@media only screen and (max-width: 37.5rem) {
    .masthead-visual__primary--layer-lower {
        background-image: v-bind("mastheadImageUrls.small1x");
    }
}

@media only screen and (max-width: 37.5rem) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (max-width: 37.5rem) and (min-resolution: 192dpi) {
    .masthead-visual__primary--layer-lower {
        background-image: v-bind("mastheadImageUrls.small2x");
    }
}

@media only screen and (min-width: 37.5rem) {
    .masthead-visual__primary--layer-lower {
        background-image: v-bind("mastheadImageUrls.large1x");
    }
}

@media only screen and (min-width: 37.5rem) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-width: 37.5rem) and (min-resolution: 192dpi) {
    .masthead-visual__primary--layer-lower {
        background-image: v-bind("mastheadImageUrls.large2x");
    }
}
</style>
