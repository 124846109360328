<template>
    <span
        class="popover__option popover__option--success strip-btn"
        v-if="itemCopied"
    >
        <span class="icon">
            <icon-checkmark-green />
        </span>
        <span class="label">Added to "{{ itemCopiedWishlist }}"</span>
    </span>
    <button
        class="popover__option strip-btn"
        @click="openMoveCopyItemToWishListModalog"
        v-else
    >
        <span class="icon"><icon-option-move></icon-option-move></span>
        <span class="label">{{ buttonText }}</span>
    </button>
</template>
<script setup>
import { computed, ref } from "vue";
import { useModalogStore } from "@/stores/modalog";
import IconOptionMove from "~/svg/ui-option-move.svg";
import IconCheckmarkGreen from "~/svg/icon-checkmark-green.svg";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import useEmitter from "@/composables/useEmitter";

const props = defineProps({
    isExpertItem: {
        type: Boolean,
        default: false,
    },
    isOwner: {
        type: Boolean,
        default: false,
    },
    item: {
        type: Object,
        default: {},
    },
    listUuid: {
        type: String,
        default: "",
    },
});

const modalogStore = useModalogStore();
const cardOptionsMenuStore = useCardOptionsMenuStore();

const itemCopied = ref(false);
const itemCopiedWishlist = ref(null);

const emitter = useEmitter();

emitter.on("item-copied", ({ wishlistName }) => {
    itemCopiedWishlist.value = wishlistName;
    itemCopied.value = true;

    setTimeout(() => {
        itemCopied.value = false;
        itemCopiedWishlist.value = null;
    }, 3000);
});

const buttonText = computed(() => {
    if (props.isExpertItem) {
        return "Add to Wish List";
    }

    return props.isOwner ? "Move to Wish List" : "Copy to Wish List";
});

const openMoveCopyItemToWishListModalog = () => {
    modalogStore.showModalog({
        type: "ModalogMoveCopyItemToWishList",
        props: {
            isOwner: props.isOwner,
            item: props.item,
            listUuid: props.listUuid,
        },
        isConfirmDisabled: true,
    });

    cardOptionsMenuStore.closeAll();
};
</script>
