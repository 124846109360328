<template>
    <div class="sidebar">
        <nav class="sidebar-nav sidebar-nav__full" v-if="!isMobile">
            <ul class="sidebar-nav__list--level-1">
                <li
                    class="sidebar-nav__item--level-1"
                    :class="{
                        'is-active': route().current() === 'user.giftlists',
                    }"
                    v-if="owner"
                >
                    <Link
                        :href="
                            route('user.giftlists', { username: user.username })
                        "
                        title="Wish Lists"
                    >
                        <span class="label">Wish Lists</span>
                    </Link>
                </li>
                <li
                    class="sidebar-nav__item--level-1"
                    :class="{
                        'is-active': [
                            'friends.index',
                            'following.theirs',
                        ].includes(route().current()),
                    }"
                >
                    <Link
                        :href="
                            route(
                                owner ? 'following.theirs' : 'friends.index',
                                { user: user.uuid },
                            )
                        "
                        title="Following"
                    >
                        <span class="icon" v-if="!owner"
                            ><SvgUiNavFollowing
                        /></span>
                        <span class="label">Following</span>
                        <span class="count">{{ user.following_count }}</span>
                    </Link>
                </li>
                <li
                    class="sidebar-nav__item--level-1"
                    :class="{
                        'is-active': [
                            'friends.followers',
                            'followers.theirs',
                        ].includes(route().current()),
                    }"
                >
                    <Link
                        :href="
                            route(
                                owner
                                    ? 'followers.theirs'
                                    : 'friends.followers',
                                { user: user.uuid },
                            )
                        "
                        title="Followers"
                    >
                        <span class="icon" v-if="!owner"
                            ><SvgUiNavFollowers
                        /></span>
                        <span class="label">Followers</span>
                        <span class="count">{{ user.followers_count }}</span>
                    </Link>
                </li>
                <li
                    class="sidebar-nav__item--level-1"
                    :class="{
                        'is-active':
                            route().current() === 'friends.find-friends',
                    }"
                    v-if="!owner"
                >
                    <Link
                        :href="route('friends.find-friends')"
                        title="Find Friends"
                    >
                        <span class="icon" v-if="!owner"
                            ><SvgNavFindFriends
                        /></span>
                        <span class="label">Find Friends</span>
                    </Link>
                </li>
            </ul>
        </nav>
        <nav class="sidebar-nav sidebar-nav__limited" v-else>
            <ul class="sidebar-nav__list--level-2">
                <li
                    class="sidebar-nav__item--level-2"
                    :class="{
                        'is-active': route().current() === 'user.giftlists',
                    }"
                    v-if="owner"
                >
                    <Link
                        :href="
                            route('user.giftlists', { username: user.username })
                        "
                        title="Wish Lists"
                    >
                        <span class="label">Wish Lists</span>
                    </Link>
                </li>
                <li
                    class="sidebar-nav__item--level-2"
                    :class="{
                        'is-active': [
                            'friends.index',
                            'following.theirs',
                        ].includes(route().current()),
                    }"
                >
                    <Link
                        :href="
                            route(
                                owner ? 'following.theirs' : 'friends.index',
                                { user: user.uuid },
                            )
                        "
                        title="Following"
                    >
                        <span class="label">Following</span>
                    </Link>
                </li>
                <li
                    class="sidebar-nav__item--level-2"
                    :class="{
                        'is-active': [
                            'friends.followers',
                            'followers.theirs',
                        ].includes(route().current()),
                    }"
                >
                    <Link
                        :href="
                            route(
                                owner
                                    ? 'followers.theirs'
                                    : 'friends.followers',
                                { user: user.uuid },
                            )
                        "
                        title="Followers"
                    >
                        <span class="label">Followers</span>
                    </Link>
                </li>
                <li
                    class="sidebar-nav__item--level-2"
                    :class="{
                        'is-active': route().current() === 'friends.find',
                    }"
                    v-if="!owner"
                >
                    <Link
                        :href="route('friends.find-friends')"
                        title="Find Friends"
                    >
                        <span class="label">Find Friends</span>
                    </Link>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { Link, usePage } from "@inertiajs/vue3";
import SvgNavFindFriends from "~/svg/ui-nav-find-friends.svg";
import SvgUiNavFollowing from "~/svg/ui-nav-following.svg";
import SvgUiNavFollowers from "~/svg/ui-nav-followers.svg";

// Props
const props = defineProps({
    isMobile: {
        type: Boolean,
        default: false,
    },
});

// State
const authUser = ref(usePage().props.auth.user?.data ?? null);
const owner = ref(usePage().props.owner?.data ?? null);

// Computed
const user = computed(() => (owner.value ? owner.value : authUser.value));
</script>
