<script setup>
import { router, useForm } from "@inertiajs/vue3";
import { useModalogStore } from "@/stores/modalog";
import Errors from "@/Components/Errors/Errors.vue";
import Modalog from "@/Components/Modalogs/Modalog.vue";
import { ref } from "vue";

const modalogStore = useModalogStore();

const vFocus = {
    mounted: (el) => el.focus(),
};

const form = useForm({
    feedback: "",
});

const isDeleted = ref(false);

const submit = () => {
    form.delete(route("profile.delete_account"), {
        preserveScroll: true,
        preserveState: true,
        onBefore: () => {
            modalogStore.isThrobbing = true;
        },
        onSuccess: (response) => {
            console.log(response);
            alert(
                "Your Gift Hero account was successfully deleted. We are sorry to see you go. You will be redirected to the homepage.",
            );
        },
        onFinish: () => {
            form.reset();
            modalogStore.isThrobbing = false;
            modalogStore.closeModalog();
        },
    });
};
</script>
<template>
    <modalog :has-header="true" size="medium">
        <template #title>Confirm Account Deletion</template>
        <template #content>
            <Errors :passed-errors="$page.props.errors" />
            <p>
                Before you go, please let us know why you are deleting your
                account.
            </p>
            <div class="form-control u-stack-label form-material">
                <label class="input-label" for="feedback"
                    >Comments (optional)</label
                >
                <textarea
                    class="encase"
                    id="feedback"
                    name="feedback"
                    rows="4"
                    v-model="form.feedback"
                    v-focus
                ></textarea>
            </div>
        </template>
        <template #confirmButton>
            <button
                class="strip-btn dialog__action-button dialog__action-button--primary"
                :disabled="form.processing"
                @click.stop="submit"
            >
                Delete My Account
            </button>
        </template>
    </modalog>
</template>
