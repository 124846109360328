import { defineStore } from "pinia";
import { ref } from "vue";

export const useHistoryStore = defineStore("history", () => {
    const history = ref({
        fromWishlistUrl: null,
    });

    function getHistoryItem(key) {
        if (!history.value.hasOwnProperty(key)) {
            return null;
        }

        const value = history.value[key];
        history.value[key] = null;

        return value;
    }

    function updateHistoryItem(key, value) {
        if (!history.value.hasOwnProperty(key)) {
            return null;
        }

        history.value[key] = value;
    }

    return {
        getHistoryItem,
        history,
        updateHistoryItem,
    };
});
