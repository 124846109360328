<script setup>
import { Head, Link } from "@inertiajs/vue3";

import Flickity from "flickity";
import "flickity-as-nav-for";
import { onMounted, ref } from "vue";
import CarouselIcons from "../Components/Welcome/CarouselIcons.vue";
import { useResizeObserver } from "@vueuse/core";

const asNavForCarousel = ref(null);
onMounted(() => {
    var homeCarousel = document.querySelector(".wishlists-visual__cards");
    var selectedIndex = homeCarousel.dataset.selected;
    var flkty = new Flickity(homeCarousel, {
        arrowShape:
            "M45 14 12 50 45 86 57.5 73 45 59 87.5 59 87.5 41 45 41 57.5 27z",
        //autoPlay: 3500,
        cellSelector: ".wishlists-visual__card",
        dragThreshold: 100,
        initialIndex: selectedIndex,
        friction: 0.25,
        percentPosition: true,
        setGallerySize: false,
        selectedAttraction: 0.035,
        wrapAround: true,
    });

    // Create visible nav for smaller screens

    const asNavFlickity = new Flickity(asNavForCarousel.value, {
        asNavFor: homeCarousel,
        dragThreshold: 600,
        pageDots: false,
        prevNextButtons: false,
        setGallerySize: false,
        wrapAround: true,
    });

    /*  This handles an edge case where the child nav would drift off from
        center when resizing the screen at very small sizes. */
    useResizeObserver(asNavForCarousel, (entries) => {
        asNavFlickity.resize();
    });

    // Reposition on load for wider active cell
    flkty.reposition();

    // Position Cells when starting to animate for wider active cell
    flkty.on("select", function () {
        flkty.positionCells();
    });

    // Clicked element is current
    flkty.on("staticClick", function (event, pointer, cellElement, cellIndex) {
        flkty.select(cellIndex);
    });

    // If last flickity slide, add class to parent for visual CSS hook to not display first item border
    flkty.on("select", function () {
        if (flkty.selectedIndex === flkty.cells.length - 1) {
            homeCarousel.classList.add("last-item");
        } else {
            homeCarousel.classList.remove("last-item");
        }
    });
});
defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    cards: Array,
    cardIndex: Number,
});
</script>

<template>
    <Head title="Gift Wish Lists, Registries, Gift Ideas & More">
        <meta name="fo-verify" content="ace4acac-728c-4f7a-b9c0-2bd0d3327c2c" />
    </Head>
    <main class="main has-white-bg">
        <!-- GH Intro -->
        <div class="intro whitespace-bottom-small">
            <div class="container-wide stacked-children">
                <div class="intro__container">
                    <div class="intro__content">
                        <h1 class="tagline-primary">
                            The Best Wish&nbsp;List&nbsp;Ever<sup>™</sup>
                        </h1>
                        <h2 class="tagline-secondary">
                            <span>Add gifts from any website.</span>
                            <span>Share with anyone.</span>
                        </h2>
                        <div class="intro__cta">
                            <Link :href="route('register')" class="btn-plain"
                                >Get Started</Link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- GH Social Proof -->
        <section class="welcome-module__social-proof">
            <div class="welcome-module__social-proof--container">
                <h3 class="title">Featured By:</h3>
                <div class="logos">
                    <figure class="logo">
                        <img
                            src="https://res.cloudinary.com/gift-hero/image/upload/v1585926177/ui/home/logo-fast-company.svg"
                            alt="Fast Company Logo"
                        />
                        <figcaption class="visuallyhidden">
                            Fast Company
                        </figcaption>
                    </figure>
                    <figure class="logo">
                        <img
                            src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/svg/home/logo-readers-digest.svg"
                            alt="Reader's Digest Logo"
                        />
                        <figcaption class="visuallyhidden">
                            Reader&apos;s Digest
                        </figcaption>
                    </figure>
                    <figure class="logo">
                        <img
                            src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/svg/home/logo-entrepreneur-360.svg"
                            alt="Entrepreneur 360"
                        />
                        <figcaption class="visuallyhidden">
                            Entrepreneur 360
                        </figcaption>
                    </figure>
                </div>
            </div>
        </section>
        <!-- GH Actions -->
        <!--Build, Add, Share-->
        <div
            class="welcome-module__actions whitespace-bottom-small"
            id="actions"
        >
            <div class="welcome-module__actions--container">
                <div class="welcome-module__actions--visual">
                    <figure class="illustration__parent">
                        <img
                            class="illustration__child"
                            src="https://res.cloudinary.com/gift-hero/image/upload/v1523636350/marketing/illustration-build-add-share.svg"
                            alt="Build. Add. Share."
                        />
                    </figure>
                </div>
                <div class="welcome-module__actions--content">
                    <h2 class="welcome-module__section-title">
                        Build. Add.&nbsp;Share.
                    </h2>
                    <div
                        class="welcome-module__section-content welcome-module__section-content--plus1"
                    >
                        <ul>
                            <li>
                                Create as many wish lists as you like, add all
                                the gifts you&nbsp;want
                            </li>
                            <li>
                                Easily add gifts from websites and shopping apps
                                or create a custom&nbsp;gift
                            </li>
                            <li>
                                Share your wish lists with others or keep it
                                just for yourself&nbsp;– it’s up to&nbsp;you
                            </li>
                        </ul>
                        <p>
                            <Link :href="route('register')"
                                >Start wish-listing</Link
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!--.gh-actions-->
        <!-- GH Platform Preview  -->
        <section
            class="welcome-module__platform-preview whitespace-top-large whitespace-bottom-large"
        >
            <div class="welcome-module__platform-preview--container">
                <div class="welcome-module__platform-preview--content">
                    <h2 class="welcome-module__section-title">
                        Easy to Add, Easy to&nbsp;Manage
                    </h2>
                    <div
                        class="welcome-module__section-content welcome-module__section-content--plus1 whitespace-bottom-small"
                    >
                        <p>
                            With Gift Hero you can keep everything you want in
                            one place from any device.
                        </p>
                    </div>
                    <div
                        class="welcome-module__platform-preview--block welcome-module__section-content"
                    >
                        <div class="visual">
                            <img
                                src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/svg/home/block-app.svg"
                                alt=""
                            />
                        </div>
                        <div class="content">
                            <p>
                                Our
                                <Link :href="route('mobile')" title=""
                                    >mobile app for iOS and Android</Link
                                >
                                makes it easy to save gifts you find on websites
                                and shopping&nbsp;apps
                            </p>
                        </div>
                    </div>
                    <div
                        class="welcome-module__platform-preview--block welcome-module__section-content"
                    >
                        <div class="visual">
                            <img
                                src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/svg/home/block-extension.svg"
                                alt=""
                            />
                        </div>
                        <div class="content">
                            <p>
                                On your computer? We have
                                <Link :href="route('browser-extensions')"
                                    >browser extensions</Link
                                >
                                for Chrome, Firefox, and Safari to quickly save
                                any gift to your wish lists
                            </p>
                        </div>
                    </div>
                    <div
                        class="welcome-module__platform-preview--block welcome-module__section-content"
                    >
                        <div class="visual">
                            <img
                                src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/svg/home/block-copy-paste.svg"
                            />
                        </div>
                        <div class="content">
                            <p>
                                Paste a website link to automatically save
                                a&nbsp;gift to your wish list
                            </p>
                        </div>
                    </div>
                </div>
                <div class="welcome-module__platform-preview--visual">
                    <div class="devices">
                        <figure class="device device--phone">
                            <img
                                src="https://res.cloudinary.com/gift-hero/image/upload/v1523636138/marketing/product-phone-xxl_ajnuv9.png"
                                srcset="
                                    https://res.cloudinary.com/gift-hero/image/upload/v1523636138/marketing/product-phone-xxl_ajnuv9.png    1x,
                                    https://res.cloudinary.com/gift-hero/image/upload/v1523636138/marketing/product-phone-xxl_2x_povzas.png 2x
                                "
                                alt="Gift Hero app on a mobile phone"
                            />
                        </figure>
                        <figure class="device device--laptop">
                            <img
                                src="https://res.cloudinary.com/gift-hero/image/upload/v1523636138/marketing/product-laptop-xxl_jtpgf7.png"
                                srcset="
                                    https://res.cloudinary.com/gift-hero/image/upload/v1523636138/marketing/product-laptop-xxl_jtpgf7.png    1x,
                                    https://res.cloudinary.com/gift-hero/image/upload/v1523636138/marketing/product-laptop-xxl_2x_xtjmv3.png 2x
                                "
                                alt="Gift Hero on a laptop computer"
                            />
                        </figure>
                        <aside class="callout">
                            <p>
                                Gift Hero runs great on all of your device’s
                                browsers – phone, tablet, and&nbsp;computer
                            </p>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
        <!-- Gift Hero for any occasion -->
        <div
            class="welcome-module welcome-module--col center-align whitespace-top whitespace-bottom"
        >
            <div class="welcome-module__container">
                <div class="wishlists-visual">
                    <h2
                        class="welcome-module__section-title whitespace-bottom-small"
                    >
                        Gift Hero for any&nbsp;occasion
                    </h2>
                    <nav>
                        <ul
                            class="wishlists-visual__navigation"
                            ref="asNavForCarousel"
                        >
                            <li class="item" v-for="(card, key) in cards">
                                <div class="icon">
                                    <carousel-icons :icon="key" />
                                </div>
                                <span class="title">{{ card.title }}</span>
                            </li>
                        </ul>
                    </nav>
                    <div ref="carousel" class="wishlists-visual__carousel">
                        <div
                            class="wishlists-visual__cards"
                            :data-selected="cardIndex || 0"
                        >
                            <div
                                class="wishlists-visual__card"
                                v-for="(card, key) in cards"
                            >
                                <div class="icon">
                                    <carousel-icons :icon="key" />
                                </div>
                                <h3>{{ card.title }}</h3>
                                <div class="content">
                                    <p>{{ card.description }}</p>
                                    <Link
                                        class="btn-alt btn-alt--small"
                                        :href="card.link.url"
                                        title=""
                                        >{{ card.link.text }}</Link
                                    >
                                </div>
                            </div>
                        </div>
                        <div
                            class="wishlists-visual__focus"
                            id="carousel-focus"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Read minds -->
        <section class="welcome-module">
            <div class="checklist-visual">
                <div class="checklist-visual__visual">
                    <img
                        src="https://res.cloudinary.com/gift-hero/image/upload/v1685819266/lib/svg/home/illustration-cystal-ball.svg"
                        alt="Crystal Ball"
                    />
                </div>
                <div class="checklist-visual__content">
                    <h2
                        class="welcome-module__section-title welcome-module__section-title--minus1"
                    >
                        When it comes to gift giving, forget trying to
                        read&nbsp;minds
                    </h2>
                    <ul>
                        <li>
                            <h3>Make &ldquo;hard to buy for&rdquo; easy</h3>
                            <p>
                                Always buy a meaningful and
                                appreciated&nbsp;gift.
                            </p>
                        </li>
                        <li>
                            <h3>No more bad gifts</h3>
                            <p>
                                Useless and unwanted gifts bum
                                everyone&nbsp;out.
                            </p>
                        </li>
                        <li>
                            <h3>A thoughtful surprise</h3>
                            <p>
                                Get the perfect present. It&apos;s better for
                                you, them, and the&nbsp;environment.
                            </p>
                        </li>
                        <li>
                            <h3>Easy to ask</h3>
                            <p>
                                Gift Hero makes sharing a wish list
                                super&nbsp;simple.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!--.welcome-module-->
    </main>
</template>

<style lang="scss" scoped>
@use "@sass/welcome.scss";
</style>

<style lang="scss">
@use "@sass/welcome/plugins/_flickity.scss"; // Override default styles for Flickity controls
@use "@sass/welcome/modules/wishlist-visual";
</style>
