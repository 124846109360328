<script setup>
import { computed, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import ConfirmedGiftsLayout from "@/Layouts/ConfirmedGiftsLayout.vue";
import GHLogoGift from "~/svg/gh-logo-gift.svg";
import IconAlert from "~/svg/alert.svg";
import IconHint from "~/svg/icon-hint.svg";
import InertiaPagination from "@/Components/Pagination/InertiaPagination.vue";
import ItemCard from "@/Components/Cards/Item/ItemCard.vue";
import Sidebar from "@/Pages/MyGiftHero/Partials/Sidebar.vue";

// Props
const props = defineProps({
    items: {
        type: Array,
        default: [],
        required: true,
    },
});

// State
const isSpoiled = ref(false);

// Computed
const spoilerAlertVisible = computed(() => {
    if (usePage().props.items.meta.current_page > 1) {
        isSpoiled.value = true;
        return false;
    } else {
        return !isSpoiled.value && usePage().props.items.data.length > 0;
    }
});
</script>
<template>
    <Head :title="`Confirmed for You`"></Head>
    <ConfirmedGiftsLayout>
        <template #sidebar-widescreen>
            <Sidebar />
        </template>
        <template #sidebar-mobile-top>
            <Sidebar is-mobile="true" />
        </template>
        <template #content>
            <header class="grid-header grid-header--simple">
                <div class="grid-header__info">
                    <h2 class="grid-header__title">Confirmed for You</h2>
                    <div
                        class="grid-header__description"
                        v-if="$page.props.items.data.length"
                    >
                        <p>
                            These are gifts that others have confirmed for you.
                            Be sure to thank them!
                        </p>
                    </div>
                    <div
                        class="hint-panel margin-top-large has-shadow"
                        v-if="!$page.props.items.data.length"
                    >
                        <div class="hint-panel__body">
                            <div class="hint-panel__header">
                                <div class="hint-panel__gh-logo">
                                    <GHLogoGift />
                                </div>
                                <div class="hint-panel__title">
                                    No one has confirmed any gifts for you… yet!
                                </div>
                            </div>
                            <div class="hint-panel__content">
                                <p>
                                    If you build it, they will come! Share a
                                    <a :href="route('friends.index')"
                                        >wish list</a
                                    >
                                    or just a single gift with someone you love…
                                    they can’t read minds you know.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <!--Spoiler-->
            <div class="spoiler-warning" v-if="spoilerAlertVisible">
                <div class="spoiler-warning__title">
                    <icon-alert />
                    <span>Spoiler Alert</span>
                </div>
                <div class="spoiler-warning__content">
                    <p>
                        When you click or tap &ldquo;Reveal Confirmed,&rdquo;
                        you will see <strong>all gifts</strong> that have been
                        confirmed for you and who confirmed them.
                    </p>
                </div>
                <div class="spoiler-warning__cta">
                    <button
                        class="btn-plain-large"
                        @click="isSpoiled = !isSpoiled"
                    >
                        Reveal Confirmed
                    </button>
                </div>
                <div class="spoiler-warning__hint">
                    <aside class="hint-panel hint-panel__quick-tip">
                        <i class="icon">
                            <icon-hint />
                        </i>
                        <div class="content">
                            <p>
                                To reveal gifts confirmed from a singular wish
                                list, go to that wish list and click or tap the
                                cog icon for more options. Then view "Show
                                Confirmed Gifts".
                            </p>
                        </div>
                    </aside>
                </div>
            </div>
            <template v-if="isSpoiled">
                <div class="list-grid">
                    <item-card
                        v-for="item of $page.props.items?.data"
                        :item="item"
                        :purchaser="item?.purchaser ?? null"
                        :purchasee="$page.props.auth.user?.data ?? null"
                    />
                </div>
                <inertia-pagination
                    :links="$page.props.items.links"
                    :meta="$page.props.items.meta"
                />
            </template>
        </template>
        <template #sidebar-mobile-bottom></template>
    </ConfirmedGiftsLayout>
</template>
