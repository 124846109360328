<template>
    <Head>
        <title>My Gift Hero | Gift Hero</title>
    </Head>
    <WishListsLayout>
        <template #sidebar-widescreen>
            <Sidebar />
        </template>
        <template #sidebar-mobile-top>
            <Sidebar :is-mobile="true" />
        </template>
        <template #wishlists-header-title>My Wish Lists</template>
        <template #wishstlists-header-subtitle
            >The things I want for myself</template
        >
        <template #expertlists-header-title>My Expert Lists</template>
        <template #expertlists-header-subtitle
            >Build lists of your favorite recommendations</template
        >
    </WishListsLayout>
</template>
<script setup>
import WishListsLayout from "@/Layouts/WishListsLayout.vue";
import Sidebar from "./Partials/Sidebar.vue";
import { usePage } from "@inertiajs/vue3";
const owner = usePage().props.owner?.data;
</script>
