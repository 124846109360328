<template>
    <button
        class="popover__option strip-btn"
        @click.stop="handleClick"
        :disabled="copied"
    >
        <span class="icon">
            <svg-green-check v-if="copied" />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 512 512"
                v-else
            >
                <g fill-rule="evenodd" clip-rule="evenodd">
                    <path
                        d="M503.47 132.27L379.73 8.53c-11.38-11.37-29.85-11.37-41.23 0L214.77 132.27c-11.4 11.38-11.4 29.85 0 41.26l20.6 20.6L359.1 70.4l82.5 82.5-123.73 123.73 20.63 20.63c11.38 11.38 29.85 11.38 41.23 0l123.73-123.73c11.4-11.4 11.4-29.88 0-41.26zM276.63 317.87L152.9 441.6l-82.5-82.5 123.73-123.73-20.63-20.63c-11.38-11.38-29.85-11.38-41.23 0L8.53 338.47c-11.37 11.4-11.37 29.88 0 41.26l123.74 123.74c11.38 11.37 29.85 11.37 41.23 0l123.73-123.74c11.4-11.38 11.4-29.85 0-41.26l-20.6-20.6z"
                    ></path>
                    <path
                        d="M173.5 338.47c11.4 11.4 29.86 11.4 41.27 0L338.5 214.74c11.38-11.38 11.38-29.83 0-41.2-11.4-11.42-29.85-11.42-41.27 0L173.5 297.25c-11.38 11.38-11.38 29.83 0 41.2z"
                    ></path>
                </g>
            </svg>
        </span>
        <span class="label">{{ buttonText }}</span>
    </button>
</template>
<script setup>
import { computed } from "vue";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";
import { useClipboard } from "@vueuse/core";
import SvgGreenCheck from "~/svg/icon-checkmark-green.svg";

const props = defineProps({
    copyUrl: {
        type: String,
        required: true,
    },
});

const cardOptionsMenuStore = useCardOptionsMenuStore();
const { text, copy, copied, isSupported } = useClipboard({
    source: props.copyUrl,
});

const buttonText = computed(() => {
    return copied.value
        ? "Link Copied to Clipboard!"
        : "Copy Link to Clipboard";
});

const handleClick = () => {
    cardOptionsMenuStore.closeAll(1500);
    copy(props.copyUrl);
};
</script>
