/**
 * API service
 */
export function useApiService() {
    function blockUser(uuid = null) {
        if (!uuid) return false;

        return axios.post(route("user.block", { user: uuid }));
    }

    function followUser(uuid = null) {
        if (!uuid) return false;

        return axios.post(route("user.follow", { uuid }), {
            preserveScroll: true,
            preserveState: true,
        });
    }

    function unblockUser(uuid = null) {
        if (!uuid) return false;

        return axios.delete(route("user.unblock", { user: uuid }));
    }

    function unfollowUser(uuid = null) {
        if (!uuid) return false;

        return axios.delete(route("user.unfollow", { uuid }));
    }

    return {
        blockUser,
        followUser,
        unblockUser,
        unfollowUser,
    };
}
