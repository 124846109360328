<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import AuthenticationCard from "@/Components/AuthenticationCard.vue";
import AuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue";
import Checkbox from "@/Components/Checkbox.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import TextInput from "@/Components/TextInput.vue";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};
</script>

<template>
    <Head title="Log in" />
    <main class="main" role="main">
        <section class="form-module form-module--contained whitespace-bottom">
            <div class="form-module__header form-module__header--arrow-down">
                <h2 class="form-module__title">Log In to Gift Hero</h2>
                <p class="form-module__description">
                    If you don&rsquo;t have an account yet,
                    <Link href="route('register')" title="Sign Up"
                        ><strong>sign up for Gift Hero</strong></Link
                    >
                    now!
                </p>
            </div>
            <div class="form-module__login-options">
                <div
                    v-if="status"
                    class="mb-4 font-medium text-sm text-green-600"
                >
                    {{ status }}
                </div>
                <form class="form form-material" @submit.prevent="submit">
                    <div
                        class="form-control u-stack-label"
                        :class="{ 'is-error': form.errors.email }"
                    >
                        <label class="input-label" for="email">Email</label>
                        <TextInput
                            id="email"
                            v-model="form.email"
                            type="email"
                            required
                            autofocus
                            placeholder="you@example.com"
                        />
                        <div
                            v-show="form.errors.email"
                            class="form-control__response"
                        >
                            {{ form.errors.email }}
                        </div>
                    </div>
                    <!-- Password -->
                    <div
                        class="form-control u-stack-label"
                        :class="{ 'is-error': form.errors.password }"
                    >
                        <label class="input-label" for="password"
                            >Password</label
                        >
                        <TextInput
                            id="password"
                            v-model="form.password"
                            type="password"
                            required
                            autocomplete="current-password"
                        />
                        <div
                            class="form-control__response"
                            v-show="form.errors.password"
                        >
                            {{ form.errors.password }}
                        </div>
                    </div>
                    <!-- TODO: ? Remember me -->
                    <!-- <div class="block mt-4">
                        <label class="flex items-center">
                            <Checkbox v-model:checked="form.remember" name="remember" />
                            <span class="ml-2 text-sm text-gray-600">Remember me</span>
                        </label>
                    </div> -->
                    <!-- Log In -->
                    <div class="form-control center-align">
                        <button
                            type="submit"
                            class="btn-plain form-material__submit"
                            :disabled="form.processing"
                        >
                            Log In
                        </button>
                    </div>
                </form>
            </div>
            <!-- Reset Password / Sign Up -->
            <div class="form-meta center-align">
                <span class="content-divider"></span>
                <p v-show="canResetPassword">
                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        >Forgot your password?</Link
                    >
                </p>
                <span class="content-divider"></span>
                <p>
                    <Link :href="route('register')">Sign up for Gift Hero</Link>
                </p>
            </div>
        </section>
    </main>
</template>
