<template>
    <Create
        :list-uuid="listUuid"
        @hide-dialog="hideDialog()"
        @url-parsed="urlParsed"
        @confirm-custom="confirmCustom"
        v-if="showCreate"
    />
    <ConfirmUrl
        :list-type="props.listType"
        :list-uuid="props.listUuid"
        :data="urlData"
        @item-added="itemAdded"
        @hide-dialog="hideDialog()"
        v-else-if="showConfirmUrl"
    />
    <ConfirmCustom
        :custom="custom"
        :list-type="listType"
        @item-added="itemAdded"
        @hide-dialog="hideDialog()"
        v-else-if="showConfirmCustom"
    />
</template>
<script setup>
import { computed, reactive, ref } from "vue";
import Create from "./Create.vue";
import ConfirmUrl from "./ConfirmUrl.vue";
import ConfirmCustom from "./ConfirmCustom.vue";
import { useModalogStore } from "@/stores/modalog.js";

const modalogStore = useModalogStore();

modalogStore.$subscribe(
    (mutation, state) => {
        console.log("modalogStore.$subscribe callback");
        console.log(mutation, state);
        console.log(state.hasModalog);

        // if (mutation.type === 'hasModalog' && mutation.payload === false) {
        if (!!state.hasModalog) {
            step.value = 1;
        }
    },
    { detached: true },
);

const props = defineProps(["listType", "listUuid"]);

//--- State
const custom = reactive({});
const step = ref(1);
const type = ref("url");
const urlData = reactive({});

//--- Computed
const showCreate = computed(() => {
    return step.value === 1 && modalogStore.hasModalog;
});

const showConfirmUrl = computed(() => {
    return step.value === 2 && type.value === "url" && modalogStore.hasModalog;
});

const showConfirmCustom = computed(() => {
    return (
        step.value === 2 && type.value === "custom" && modalogStore.hasModalog
    );
});

const confirmCustom = (data) => {
    console.log(data);
    custom.value = data;
    step.value = 2;
    type.value = "custom";
};

//--- Methods
function hideDialog() {
    modalogStore.closeModalog();
    // this.$emit('hide-add-item-dialog')
    // this.$store.commit('hideModal')
}

function itemAdded(item) {
    // this.$root.$emit('item-added', item)
    // TODO: Handle `pagination` and `orderBy: sequence`
    router.reload({ only: ["giftlist"] });
}

function urlParsed(data) {
    console.log(data);
    urlData.value = data;
    step.value = 2;
    type.value = "url";
}

// export default {
//     created () {
//         this.$store.subscribe((mutation, state) => {
//             if (mutation.type === 'hasModalog' && mutation.payload === false) {
//                 this.step = 1
//             }
//         })
//     },
// }
</script>
