<script setup>
import { computed, inject, onBeforeUpdate, onMounted, ref } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import { useModalogStore } from "@/stores/modalog";
import ImageUpload from "@/Components/Actions/ImageUpload.vue";
import Modalog from "@/Components/Modalogs/Modalog.vue";
import { useImageUpload } from "@/composables/useImageUpload";
import * as Sentry from "@sentry/vue";

const route = inject("route");
const CLOUDINARY_UPLOAD_PRESET = "user_cover_photo";
const {
    cancel,
    dropzoneRef,
    fetchUploadSignature,
    file,
    form,
    imageAdded,
    imageUploaded,
    resetDropzone,
    uploadImage,
} = useImageUpload();
const modalogStore = useModalogStore();

const props = defineProps({
    showModalogOnCancel: {
        type: Object,
        default: null,
        required: false,
    },
});

onMounted(async () => {
    await fetchUploadSignature(CLOUDINARY_UPLOAD_PRESET);
});

onBeforeUpdate(async () => {
    await fetchUploadSignature(CLOUDINARY_UPLOAD_PRESET);
});

const imageUpload = ref(null);
const user = usePage().props?.auth.user.data ?? null;

const hasHeaderPhoto = computed(() => {
    return user?.cover_photo ?? null;
});

const isConfirmDisabled = computed(() => {
    return modalogStore.isConfirmDisabled;
});

// const cancel = () => {
//     resetDropzone();
//
//     // TODO: Move this into `useImageUpload` composable; not sure where we might be using this functionality
//     if (props.showModalogOnCancel) {
//         modalogStore.showModalog({
//             type: props.showModalogOnCancel.type,
//             props: props.showModalogOnCancel.props,
//             isConfirmDisabled: props.showModalogOnCancel.isConfirmDisabled
//         });
//     } else {
//         modalogStore.closeModalog();
//     }
// };

const openRemoveUserProfileHeaderImageModalog = () => {
    modalogStore.showModalog({
        type: "ModalogConfirmRemoveHeaderImage",
        props: {
            content:
                "Your header photo makes your user profile more personal. Do you really want to remove it?",
            routeForDelete: route("giftlists.deleted_cover_photo"),
            title: "Remove Current Header Photo?",
            showModalogOnClose: "ModalogUserProfileHeaderImageUpload",
            showModalogOnCloseProps: {
                isConfirmDisabled: false,
            },
        },
    });
};

const saveUserProfileHeaderPhoto = () => {
    form.post(route("profile.update_cover_photo"), {
        onBefore: () => {
            modalogStore.isThrobbing = true;
        },
        onSuccess: () => {
            modalogStore.closeModalog();
            resetDropzone();
            router.reload({
                force: true,
                forceFormData: true,
                replace: true,
            });
        },
        onFinish: () => {
            modalogStore.isThrobbing = false;
        },
    });
};

const uploadAndSave = async () => {
    modalogStore.isThrobbing = true;

    try {
        uploadImage().then((responseData) => {
            modalogStore.isThrobbing = false;
            form.imageUuid = responseData.asset_id;
            form.asset_id = responseData.asset_id;
            form.public_id = responseData.public_id;
            saveUserProfileHeaderPhoto();
        });
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
    }
};
</script>
<template>
    <modalog confirm-button-text="Save" :has-header="true">
        <template #title>{{
            hasHeaderPhoto ? "Change Header Photo" : "Upload Header Photo"
        }}</template>
        <template #content>
            <div class="margin-bottom-small margin-top-small">
                <image-upload
                    ref="dropzoneRef"
                    @image-added="imageAdded"
                    @image-uploaded="imageUploaded"
                ></image-upload>
            </div>
            <div class="center-align whitespace-top-small">
                <a
                    class="button is-small is-primary is-outlined"
                    href="javascript:void(0)"
                    @click.stop="openRemoveUserProfileHeaderImageModalog"
                    v-if="hasHeaderPhoto"
                    >Remove current header photo</a
                >
            </div>
        </template>
        <template #cancelButton>
            <button class="strip-btn dialog__action-button" @click="cancel">
                Cancel
            </button>
        </template>
        <template #confirmButton>
            <button
                class="strip-btn dialog__action-button dialog__action-button--primary"
                @click="uploadAndSave"
                :disabled="!file"
            >
                Save
            </button>
        </template>
    </modalog>
</template>
