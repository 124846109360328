<template>
    <Head>
        <meta v-if="noIndex" name="robots" content="noindex, follow" />
    </Head>
    <AppLayout>
        <SidebarLayout>
            <template #sidebar-widescreen>
                <slot name="sidebar-widescreen"></slot>
            </template>
            <template #content>
                <!-- User Profile Header -->
                <UserProfileHeader />
                <slot name="sidebar-mobile-top" />
                <div class="grid-header__filter"></div>
                <!-- No Wish/Expert Lists -->
                <template v-if="hasNoLists && !isOwner">
                    <div
                        class="whitespace-bottom-large copy-block center-align-flex"
                    >
                        <figure>
                            <img
                                src="https://res.cloudinary.com/gift-hero/image/upload/v1714156320/lib/svg/icon-header-empty-box.svg"
                                alt="Empty Box"
                                width="66"
                                height="66"
                            />
                        </figure>
                        <h2 class="title">Awww&hellip;</h2>
                        <p>
                            {{
                                owner.is_organization
                                    ? owner.presentation_name
                                    : owner.first_name
                            }}
                            doesn&apos;t have any lists yet.<br />
                            Check back!
                        </p>
                    </div>
                </template>
                <!-- Wish Lists / Expert Lists -->
                <template v-else>
                    <!--My Wish Lists-->
                    <section
                        class="list-grouping whitespace-bottom"
                        v-if="showWishListsSection"
                    >
                        <header class="list-group whitespace-bottom-small">
                            <div class="list-group__header">
                                <i class="list-group__header--icon"
                                    ><SvgIconHeaderWishList
                                /></i>
                                <div class="list-group__header--text">
                                    <h2 class="list-group__header--title">
                                        <slot
                                            name="wishlists-header-title"
                                        ></slot>
                                    </h2>
                                    <span class="list-group__header--subtitle">
                                        <slot
                                            name="wishstlists-header-subtitle"
                                        ></slot>
                                    </span>
                                </div>
                            </div>
                            <div class="list-group__actions">
                                <div
                                    class="list-group__actions--action hide-at-nav-swap-down"
                                >
                                    <CreateWishListButton
                                        type="wish"
                                        v-if="isOwner"
                                    />
                                </div>
                            </div>
                        </header>
                        <div class="list-grid" ref="listContainer">
                            <!--Lists Grid-->
                            <ListCard
                                :list="list"
                                :logged-in="true"
                                :owner="owner"
                                :key="list.uuid"
                                @click.stop
                                v-for="list of $page.props.giftlists.data"
                            />
                        </div>
                        <footer class="hide-at-nav-swap-up list-group">
                            <div class="list-group__actions">
                                <div class="list-group__actions--action">
                                    <create-wish-list-button
                                        type="wish"
                                        v-if="isOwner"
                                    ></create-wish-list-button>
                                </div>
                            </div>
                        </footer>
                    </section>
                    <!--My Expert Lists-->
                    <section
                        class="list-grouping whitespace-bottom-large"
                        :class="{
                            'list-grouping--gradient-divider':
                                hasListGroupingDivider,
                        }"
                        v-if="showExpertListsSection"
                    >
                        <header class="list-group whitespace-bottom-small">
                            <div class="list-group__header">
                                <i class="list-group__header--icon"
                                    ><SvgIconHeaderExpertList
                                /></i>
                                <div class="list-group__header--text">
                                    <h2 class="list-group__header--title">
                                        <slot
                                            name="expertlists-header-title"
                                        ></slot>
                                    </h2>
                                    <span class="list-group__header--subtitle">
                                        <slot
                                            name="expertlists-header-subtitle"
                                        ></slot>
                                    </span>
                                </div>
                            </div>
                            <div class="list-group__actions">
                                <div
                                    class="list-group__actions--action hide-at-nav-swap-down"
                                >
                                    <CreateWishListButton
                                        type="expert"
                                        v-if="isOwner"
                                    />
                                </div>
                            </div>
                        </header>
                        <div class="list-grid" ref="listContainer">
                            <!--Empty state-->
                            <aside
                                class="cta-banner"
                                v-if="!$page.props?.expertlists.data.length"
                            >
                                <div class="cta-banner__content">
                                    <h2 class="cta-banner__headline">
                                        Here&rsquo;s Your Chance to
                                        Show&nbsp;Off
                                    </h2>
                                    <h3 class="cta-banner__subhead">
                                        Make lists of your best ideas
                                        for&nbsp;others
                                    </h3>
                                    <p>
                                        Are your friends always asking you for
                                        advice on what to get? Now you can build
                                        lists they can browse for ideas. Whether
                                        it's your favorite gear, or even your
                                        own products, anyone can add these gifts
                                        to their own wish lists.
                                    </p>
                                    <p>
                                        <template v-if="!isMobile">
                                            <CreateWishListButton
                                                type="expert"
                                                isBasic
                                            />
                                            <br /><br />
                                        </template>
                                        <span v-if="!isMobile">or </span
                                        ><a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://support.gifthero.com/page/getting-started/"
                                            ><strong>Learn More</strong></a
                                        >
                                    </p>
                                </div>
                            </aside>
                            <!--Lists Grid-->
                            <list-card
                                :list="list"
                                :logged-in="true"
                                :owner="user"
                                :key="list.uuid"
                                @click.stop
                                v-for="list in $page.props.expertlists.data"
                            ></list-card>
                        </div>
                        <footer class="hide-at-nav-swap-up list-group">
                            <div class="list-group__actions">
                                <div class="list-group__actions--action">
                                    <CreateWishListButton type="expert" />
                                </div>
                            </div>
                        </footer>
                    </section>
                </template>
            </template>
            <template #sidebar-mobile-bottom>
                <slot name="sidebar-mobile-bottom" />
            </template>
        </SidebarLayout>
    </AppLayout>
</template>
<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useAuthUser } from "@/composables/useAuthUser.js";
import AppLayout from "@/Layouts/AppLayout.vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";
import SidebarLayout from "@/Layouts/SidebarLayout.vue";
//--- Components ---
import CreateWishListButton from "@/Components/Actions/CreateWishListButton.vue";
import ListCard from "@/Components/Cards/ListCard.vue";
import UserProfileHeader from "@/Components/User/UserProfileHeader.vue";
//--- SVG ---
import SvgIconHeaderExpertList from "~/svg/icon-header-expert-list.svg";
import SvgIconHeaderWishList from "~/svg/icon-header-wish-list.svg";
import SvgIconPlus from "~/svg/icon-plus.svg";

defineOptions({
    layout: [BaseLayout],
});

const user = useAuthUser();
const isOwner = usePage().props.isOwner;
const owner = usePage().props.owner?.data;
const giftlists = usePage().props.giftlists.data;
const expertlists = usePage().props.expertlists?.data;

// Computed
const hasNoLists = computed(() => {
    return !giftlists?.length && !expertlists?.length;
});

const hasListGroupingDivider = computed(() => {
    return isOwner ? true : hasWishAndExpertLists.value;
});

const hasWishAndExpertLists = computed(() => {
    return giftlists?.length > 0 && expertlists?.length > 0;
});

const noIndex = computed(() => {
    return !owner.is_gift_hero && !owner.is_organization;
});

const showExpertListsSection = computed(() => {
    return isOwner ? true : (expertlists?.length > 0 ?? false);
});

const showWishListsSection = computed(() => {
    return isOwner ? true : (giftlists?.length > 0 ?? false);
});

const userShortPresentationName = computed(() => {
    return owner.is_organization ? owner.presentation_name : owner.first_name;
});
</script>
