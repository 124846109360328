<template>
    <div class="card card__create list-grid__item">
        <div class="card__container">
            <button class="strip-btn card__add-btn" @click="showAddItemModalog">
                <div class="label">
                    <span class="icon"><SvgAdd /></span>
                    <span class="text">{{ text }}</span>
                </div>
            </button>
        </div>
    </div>
</template>
<script setup>
import { useModalogStore } from "@/stores/modalog.js";
import SvgAdd from "~/svg/icon-add.svg";

const modalogStore = useModalogStore();
const { showModalog } = modalogStore;

const props = defineProps({
    list: {
        type: Object,
        required: true,
    },
    text: {
        type: String,
        default: "Add a Gift",
    },
});

function showAddItemModalog() {
    showModalog({
        type: "ModalogAddGift",
        props: {
            listType: props.list.type,
            listUuid: props.list.uuid,
        },
    });
}

// export default {
//     components: {
//         'svg-add': SvgAdd,
//     },
//     props: {
//         list: {
//             type: Object,
//             required: true
//         },
//         text: {
//             type: String,
//             default: 'Add a Gift'
//         }
//     },
// }
</script>
