<script setup>
import { computed } from "vue";
import { router } from "@inertiajs/vue3";
import IconSpoilHide from "~/svg/icon-ui-spoil-hide.svg";
import IconSpoilShow from "~/svg/icon-ui-spoil-show.svg";
import { useModalogStore } from "@/stores/modalog";
import { useWishlistStore } from "@/stores/wishlist";

const modalogStore = useModalogStore();
const wishlistStore = useWishlistStore();

const props = defineProps({
    isStandalone: {
        type: Boolean,
        default: false,
    },
    list: {
        type: Object,
        default: null,
        required: true,
    },
});

const buttonText = computed(() => {
    return wishlistStore.isSpoiled
        ? "Hide Confirmed Gifts"
        : "Show Confirmed Gifts";
    // return isSpoiled.value ? 'Hide Confirmed Gifts' : 'Show Confirmed Gifts';
});

const isSpoiled = computed(() => {
    return props.list.hasOwnProperty("isSpoiled")
        ? props.list.isSpoiled
        : false;
});

const toggleSpoiler = () => {
    const currentRoute = route().current();

    switch (currentRoute) {
        case "list.show":
            router.get(route("list.confirmed", { giftlist: props.list.uuid }));
            break;
        case "list.confirmed":
            router.get(route("list.show", { giftlist: props.list.uuid }));
            break;
        default:
            openSpoilListConfirmationModalog();
            break;
    }

    // TODO: Not sure if we'll need any  of this when we get to Confirmed Gifts implementation, leaving here for now
    // if (isSpoiled.value) {
    //     props.list.isSpoiled = false;
    //     this.$parent.isVisible = false;
    //     this.$store.commit('wishlists/showConfirmedGifts', false);
    //     this.$store.dispatch('closeModalog').then()
    // } else {
    //     openSpoilListConfirmationModalog();
    // }
};

const openSpoilListConfirmationModalog = () => {
    modalogStore.showModalog({
        type: "modalog-list-spoiler-confirmation",
        props: {
            list: props.list,
        },
        isConfirmDisabled: false,
    });
    this.$store
        .dispatch("showModalog", {
            type: "modalog-list-spoiler-confirmation",
            props: {
                list: this.list,
            },
            isConfirmDisabled: false,
        })
        .then();
};
</script>
<template>
    <button
        class="popover__option strip-btn"
        @click="toggleSpoiler"
        v-if="!isStandalone"
    >
        <span class="icon">
            <icon-spoil-hide v-if="wishlistStore.isSpoiled"></icon-spoil-hide>
            <icon-spoil-show v-else></icon-spoil-show>
        </span>
        <span class="label">{{ buttonText }}</span>
    </button>
    <div v-else>
        <button
            class="button is-teensy is-primary is-inverted has-shadow has-icon"
            @click="toggleSpoiler"
        >
            <i class="icon is-subdued-75">
                <icon-spoil-hide v-if="isSpoiled"></icon-spoil-hide>
                <icon-spoil-show v-else></icon-spoil-show>
            </i>
            {{ buttonText }}
        </button>
    </div>
</template>
