/**
 * URL utils.
 */
export default class urlUtils {
    /**
     * Get a querystring parameter by name.
     * @param param
     * @return {*}
     */
    static getQuerystringParam(param) {
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] === param) {
                return pair[1];
            }
        }
        return false;
    }

    /**
     * Relative giftlist URL.
     * @param giftlistUuid
     * @return {string}
     */
    static giftlistUrl(giftlistUuid) {
        return `/list/${giftlistUuid}`;
    }

    /**
     * Relative user profile URL.
     * @param username
     * @return {string}
     */
    static profileUrl(username) {
        return `/${username}`;
    }
}
