<script setup>
import { useModalogStore } from "@/stores/modalog.js";

const modalogStore = useModalogStore();
</script>

<template>
    <main id="app" class="main" role="main">
        <div class="main__container">
            <slot />
        </div>
    </main>
    <!--Dynamic Modalog Component-->
    <transition name="fade" mode="in-out">
        <component
            :is="modalogStore.modalogType"
            v-bind="modalogStore.modalogProps"
        ></component>
    </transition>
    <!--Yup. That's it.-->
</template>
