<template>
    <Head>
        <title>Friends | Wish Lists</title>
    </Head>
    <WishListsLayout>
        <template #sidebar-widescreen>
            <Sidebar />
        </template>
        <template #sidebar-mobile-top>
            <Sidebar is-mobile="true" />
        </template>
        <template #wishlists-header-title>Wish Lists</template>
        <template #wishstlists-header-subtitle
            >Things {{ ownerPresentationShortName }} would like</template
        >
        <template #expertlists-header-title>Expert Lists</template>
        <template #expertlists-header-subtitle
            >Things {{ ownerPresentationShortName }} suggests</template
        >
    </WishListsLayout>
</template>
<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import WishListsLayout from "@/Layouts/WishListsLayout.vue";
import Sidebar from "./Partials/Sidebar.vue";

// State
const owner = usePage().props.owner?.data;

// Computed
const ownerPresentationShortName = computed(() => {
    return owner.is_organization ? owner.presentation_name : owner.first_name;
});
</script>
