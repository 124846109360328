<template>
    <a
        :href="props.item.original_url"
        v-bind:data-hostname="props.item.product?.retailer?.hostname"
        target="_blank"
        rel="noopener"
        class="button is-success is-bold is-caps gtm-item-buy"
        @click="handleClick"
        :title="title"
        >Buy
        <template v-if="props.item.product?.retailer.name !== 'custom_item'">
            <span>from {{ props.item.product?.retailer.name }}</span>
        </template>
    </a>
</template>
<script setup>
import { computed } from "vue";
import { useCardOptionsMenuStore } from "@/stores/cardOptionsMenu";

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const cardOptionsMenuStore = useCardOptionsMenuStore();

const title = computed(() => {
    return `Buy ${props.item.product.retailer.name !== "custom_item" ? `from ${props.item.product?.retailer?.name}` : null}`;
});
const handleClick = () => {
    cardOptionsMenuStore.closeAll(0);
    window.open(props.item.url, "_blank", "noopener");
};
</script>
