<template>
    <!-- Dropzone, shown after the above button is selected: -->
    <div class="custom-image__dropzone">
        <Dropzone
            id="myVueDropzone"
            class="dropzone"
            ref="dropzoneRef"
            :include-styling="false"
            :options="dzOptions"
            v-on:vdropzone-file-added="imageAdded"
            v-on:vdropzone-removed-file="imageRemoved"
            v-on:vdropzone-sending="imageUploading"
            v-on:vdropzone-success="imageUploaded"
            v-on:vdropzone-error="imageUploadError"
        />
    </div>
</template>
<script setup>
import { ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import Dropzone from "vue2-dropzone-vue3";

//--- Emits
const emit = defineEmits([
    "image-added",
    "image-removed",
    "image-uploaded",
    "image-upload-error",
    "image-uploading",
]);

//--- Props
const props = defineProps({
    apiEndpoint: {
        type: String,
        default: "https://api.cloudinary.com/v1_1/gift-hero/image/upload",
        required: false,
    },
});

//--- State
const dropzoneRef = ref(null);
const dzFile = ref({});
const dzOptions = ref({
    acceptedFiles: ".jpg,.png,.jpeg,.gif",
    addRemoveLinks: true,
    autoProcessQueue: false,
    dictDefaultMessage: "Drag & drop or click here to upload an image",
    dictFileTooBig: "Your image must be smaller than 8MB",
    headers: {
        "X-CSRF-Token": usePage().props.auth.token,
    },
    maxFiles: 1,
    maxFilesize: 8,
    thumbnailHeight: 192,
    thumbnailWidth: null,
    uploadMultiple: false,
    url: props.apiEndpoint,
});
const errors = ref(null); // If the API fails, this will become an object of key/value errors.
const fileUploading = ref(false);
const imageUploadVisible = ref(false);
const isLoading = ref(false);
const isValidPrice = ref(true);
const itemComments = ref("");
const transitionName = ref(null);
const useCustomDzOptions = ref(true);

//--- Methods
function imageAdded(file) {
    emit("image-added", file);
}

function imageRemoved(file, error, xhr) {
    emit("image-removed", file, error, xhr);
    dzFile.value = {};
}

function imageUploaded(file, response) {
    emit("image-uploaded", response.uuid);
    dzFile.value = file;
    fileUploading.value = false;
}

function imageUploadError(file, response) {
    emit("image-upload-error", file);
    // Custom error handling here.
    // https://gitlab.com/meno/dropzone/blob/master/src/dropzone.js#L811
    if (response.hasOwnProperty("file")) {
        for (let node of file.previewElement.querySelectorAll(
            "[data-dz-errormessage]",
        )) {
            node.textContent = response.file[0];
        }
    }

    console.log(file);
}

function imageUploading(file, xhr, formData) {
    fileUploading.value = true;
    emit("image-uploading", file, xhr, formData);
}

function removeAllFiles() {
    dropzoneRef.value.removeAllFiles(true);
}

function getQueuedFiles() {
    return dropzoneRef.value.getQueuedFiles();
}

defineExpose({
    getQueuedFiles,
    removeAllFiles,
});
</script>
