<script setup>
import { computed, ref } from "vue";
import { Link } from "@inertiajs/vue3";
// import BlockUnblockUserOption from "../actions/BlockUnblockUserCardOption.vue";
// import ClickOutside from "vue-click-outside";
// import IconEditList from "../../../svg/ui-edit-list.svg";
// import ShareUserProfile from "../actions/ShareUserProfile";
import SvgCog from "~/svg/ui-option-cog.svg";
import SvgEdit from "~/svg/ui-option-edit.svg";
// import SvgUploadPhoto from "~/svg/ui-option-upload-image.svg";
import { useAuthUser } from "@/composables/useAuthUser.js";
import { useModalogStore } from "@/stores/modalog";

const modalogStore = useModalogStore();
const user = useAuthUser();

const isVisible = ref(false);

const hasHeaderPhoto = computed(() => user.cover_photo);

const isGiftHero = computed(() => user.is_gift_hero);

const closeUserProfileOptionsMenu = () => (isVisible.value = false);

const showEditUserProfileModalog = () => {
    modalogStore.showModalog({
        type: "modalog-edit-user-profile",
        isConfirmDisabled: true,
    });
};
</script>
<template>
    <div class="masthead-visual__list-options">
        <div v-click-outside="closeUserProfileOptionsMenu">
            <input
                role="button"
                type="checkbox"
                class="popover__checkbox"
                id="list-options"
                aria-labelledby=""
                v-model="isVisible"
            />
            <label
                class="button is-primary is-small is-inverted has-icon"
                role="presentation"
                for="list-options"
                id="list-options"
                aria-hidden="true"
            >
                <i class="icon"><SvgCog /></i> Profile Options
            </label>
            <div
                class="masthead-visual__list-options--menu popover__content has-animation has-animation--top-center"
            >
                <div class="popover__options">
                    <button
                        class="popover__option strip-btn"
                        @click.stop="showEditUserProfileModalog"
                    >
                        <span class="icon"><svg-edit></svg-edit></span>
                        <span class="label">Edit Profile</span>
                    </button>
                    <Link
                        class="popover__option strip-btn"
                        :href="route('profile.show')"
                        title="Account Settings"
                    >
                        <span class="icon"><svg-cog></svg-cog></span>
                        <span class="label">Account Settings</span>
                    </Link>
                    <Link
                        class="popover__option strip-btn"
                        href="/account-settings/organization"
                        title="Organization"
                        v-if="user.is_organization"
                    >
                        <span class="icon"><svg-cog></svg-cog></span>
                        <span class="label">Organization Settings</span>
                    </Link>
                    <!-- TODO -->
                    <!-- <hr class="popover__option--divider" /> -->
                    <!-- <share-user-profile></share-user-profile> -->
                </div>
            </div>
        </div>
    </div>
</template>
