<template>
    <div class="select is-full-width" key="select">
        <select
            name="currency-code"
            v-model="selectedCurrency"
            @change="emitCurrencyCodeSelected"
        >
            <option
                v-for="(symbol, code) in $page.props.currencies"
                :value="code"
            >
                {{ symbol }}: {{ code }}
            </option>
        </select>
    </div>
</template>
<script setup>
import { ref } from "vue";

//--- Props
const props = defineProps({
    currencyCode: {
        type: String,
        default: "USD",
        required: false,
    },
});

//--- State
const selectedCurrency = ref(props.currencyCode);

//--- Emits
const emits = defineEmits(["currency-code-selected"]);

//--- Methods
function emitCurrencyCodeSelected(event) {
    emits("currency-code-selected", event.target.value);
}
</script>
