<script setup>
import { computed } from "vue";
import { Link } from "@inertiajs/vue3";
import { isEmpty } from "lodash";
import SvgPaginationArrow from "~/svg/icon-pagination-arrow.svg";

const props = defineProps({
    links: {
        type: Object,
        required: true,
    },
    meta: {
        type: Object,
        required: true,
    },
});

const isLastPage = computed(() => {
    return props.meta.current_page === props.meta.last_page;
});
</script>
<template>
    <nav
        class="pagination whitespace-bottom"
        role="navigation"
        v-show="
            !isEmpty(props.meta) &&
            !isEmpty(props.links) &&
            props.meta?.last_page > 1
        "
    >
        <ul>
            <!--First page-->
            <li>
                <span v-if="props.meta.current_page === 1">First</span>
                <Link :href="props.links.first" rel="first" v-else>First</Link>
            </li>
            <!--Previous page-->
            <li class="page-prev">
                <span
                    class="btn-arrow btn-arrow--left btn-arrow--disabled btn-arrow--small"
                    v-if="props.meta.current_page === 1"
                >
                    <svg-pagination-arrow></svg-pagination-arrow>
                </span>
                <Link
                    :href="props.links.prev"
                    class="btn-arrow btn-arrow--left btn-arrow--small"
                    :class="{
                        'btn-arrow--disabled': props.meta.current_page === 1,
                    }"
                    rel="prev"
                    preserve-scroll
                    preserve-state
                    v-else
                >
                    <svg-pagination-arrow></svg-pagination-arrow>
                </Link>
            </li>
            <!-- Numbered page list -->
            <li>
                <ol>
                    <li
                        class="page-text"
                        :class="{
                            'is-active': link.active,
                            'page-text--ellipsis': !link.url,
                        }"
                        :key="link.label"
                        v-for="link in props.meta.links.slice(1, -1)"
                    >
                        <Link
                            :href="link.url"
                            preserve-scroll
                            preserver-state
                            v-if="link.url"
                            >{{ link.label }}</Link
                        >
                        <span v-else>{{ link.label }}</span>
                    </li>
                </ol>
            </li>
            <!--Next page-->
            <li class="page-next">
                <span class="btn-arrow btn-arrow--disabled" v-if="isLastPage">
                    <svg-pagination-arrow></svg-pagination-arrow>
                </span>
                <Link
                    class="btn-arrow"
                    :class="{
                        'btn-arrow--disabled':
                            props.meta.current_page === props.meta.last_page,
                    }"
                    :disabled="isLastPage"
                    :href="links.next"
                    rel="next"
                    presrve-scroll
                    preserve-state
                    v-else
                >
                    <svg-pagination-arrow></svg-pagination-arrow>
                </Link>
            </li>
            <!--Last page-->
            <li>
                <span v-if="props.meta.current_page === props.meta.last_page"
                    >Last</span
                >
                <Link
                    :disabled="props.meta.current_page === props.meta.last_page"
                    :href="props.links.last"
                    rel="last"
                    preserve-state
                    preserve-scroll
                    v-else
                    >Last</Link
                >
            </li>
        </ul>
    </nav>
</template>
