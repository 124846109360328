<template>
    <nav class="sidebar-nav sidebar-nav__full" v-if="!isMobile">
        <ul class="sidebar-nav__list--level-1">
            <!--Wish Lists-->
            <li
                class="sidebar-nav__item--level-1"
                :class="[isActive(['bookmarks', 'user.giftlists'])]"
            >
                <Link
                    :href="route('user.giftlists', { username: user.username })"
                    title="Wish Lists"
                >
                    <span class="icon">
                        <SvgNavWishLists />
                    </span>
                    <span class="label">Wish Lists</span>
                </Link>
                <!--Wish Lists children-->
                <ul
                    class="sidebar-nav__list--level-2"
                    :class="[
                        isExpanded(['bookmarks', 'user.giftlists'])
                            ? 'is-expanded'
                            : 'is-closed',
                    ]"
                >
                    <!--Wish Lists / Mine-->
                    <li
                        class="sidebar-nav__item--level-2"
                        :class="isActive(['user.giftlists'])"
                    >
                        <Link
                            :href="
                                route('user.giftlists', {
                                    username: user.username,
                                })
                            "
                            title="Mine"
                            >Mine</Link
                        >
                    </li>
                    <!--Wish Lists / Bookmarks-->
                    <li
                        class="sidebar-nav__item--level-2"
                        :class="isActive(['bookmarks'])"
                    >
                        <Link :href="route('bookmarks')" title="Bookmarks"
                            >Bookmarks</Link
                        >
                    </li>
                </ul>
            </li>
            <!--Confirmed Gifts-->
            <li
                class="sidebar-nav__item--level-1"
                :class="[isActive(['gifts.by_you', 'gifts.for_you'])]"
            >
                <Link :href="route('gifts.by_you')" title="Confirmed Gifts">
                    <span class="icon">
                        <SvgNavConfirmedGifts />
                    </span>
                    <span class="label">Confirmed Gifts</span>
                </Link>
                <!--Confirmed Gifts / Level 2-->
                <ul
                    class="sidebar-nav__list--level-2"
                    :class="[isExpanded(['gifts.by_you', 'gifts.for_you'])]"
                >
                    <!--Confirmed Gifts / By You-->
                    <li
                        class="sidebar-nav__item--level-2"
                        :class="[isActive(['gifts.by_you'])]"
                    >
                        <Link :href="route('gifts.by_you')" title="By You"
                            >By You</Link
                        >
                    </li>
                    <!--Confirmed Gifts / For You-->
                    <li
                        class="sidebar-nav__item--level-2"
                        :class="[isActive(['gifts.for_you'])]"
                    >
                        <Link :href="route('gifts.for_you')" title="For You"
                            >For You</Link
                        >
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
    <!--Mobile-->
    <nav class="sidebar-nav sidebar-nav__limited" v-else>
        <!--Wish Lists-->
        <ul
            class="sidebar-nav__list--level-2"
            v-if="
                [
                    'bookmarks',
                    'list.index',
                    'list.show',
                    'user.giftlists',
                ].includes(route().current())
            "
        >
            <li
                class="sidebar-nav__item--level-2"
                :class="isActive(['list.index', 'list.show', 'user.giftlists'])"
            >
                <Link
                    :href="route('user.giftlists', { username: user.username })"
                    title="Mine"
                    >Mine</Link
                >
            </li>
            <li
                class="sidebar-nav__item--level-2"
                :class="isActive(['bookmarks'])"
            >
                <Link :href="route('bookmarks')" title="Bookmarks"
                    >Bookmarks</Link
                >
            </li>
        </ul>
        <ul
            class="sidebar-nav__list--level-2"
            v-else-if="
                ['gifts.by_you', 'gifts.for_you'].includes(route().current())
            "
        >
            <!--Confirmed Gifts / By You-->
            <li
                class="sidebar-nav__item--level-2"
                :class="isActive(['gifts.by_you'])"
            >
                <Link :href="route('gifts.by_you')" title="By You">By You</Link>
            </li>
            <!--Confirmed Gifts / For You-->
            <li
                class="sidebar-nav__item--level-2"
                :class="isActive(['gifts.for_you'])"
            >
                <Link :href="route('gifts.for_you')" title="For You"
                    >For You</Link
                >
            </li>
        </ul>
    </nav>
</template>
<script setup>
import { computed } from "vue";
import { Link, usePage } from "@inertiajs/vue3";
import SvgNavConfirmedGifts from "~/svg/ui-nav-confirmed-gifts.svg";
import SvgNavWishLists from "~/svg/ui-nav-wishlists.svg";

const props = defineProps({
    isMobile: {
        type: Boolean,
        default: false,
    },
});

const user = computed(() => usePage().props.auth.user.data);

// TODO: Add to auth/user in Inertia Shared Data?

const isActive = (routeNamesArray) => {
    return routeNamesArray.includes(route().current()) ? "is-active" : null;
};

const isExpanded = (routeNamesArray) => {
    return routeNamesArray.includes(route().current())
        ? "is-expanded"
        : "is-closed";
};
</script>
