import { computed, ref } from "vue";
import dayjs from "dayjs";
import { range } from "lodash";

export function useDatepicker(context) {
    const selectedDay = ref("");
    const selectedMonth = ref("");
    const selectedYear = ref("");

    const yearBirthdateEarliestAllowed = dayjs().subtract(14, "year").year();
    const monthsArray = [
        { val: 1, text: "Jan" },
        { val: 2, text: "Feb" },
        { val: 3, text: "Mar" },
        { val: 4, text: "Apr" },
        { val: 5, text: "May" },
        { val: 6, text: "Jun" },
        { val: 7, text: "Jul" },
        { val: 8, text: "Aug" },
        { val: 9, text: "Sep" },
        { val: 10, text: "Oct" },
        { val: 11, text: "Nov" },
        { val: 12, text: "Dec" },
    ];

    const birthdate = computed(() => {
        if (!isValidBirthdate.value) {
            return null;
        }

        return selectedDateFormatted.value;
    });

    const days = () => {
        let count = 31;
        const isLeapYear =
            (selectedYear.value % 4 == 0 && selectedYear.value % 100 != 0) ||
            selectedYear.value % 400 == 0;

        switch (Number(selectedMonth.value)) {
            case 2:
                count = isLeapYear ? 29 : 28;
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                count = 30;
                break;
            default:
                count = 31;
                break;
        }

        if (selectedDay.value > count) {
            selectedDay.value = count;
        }

        return range(1, count + 1, 1);
    };

    const selectedDateFormatted = computed(() => {
        return `${selectedYear.value}-${selectedMonth.value}-${selectedDay.value}`;
    });

    const yearsForBirthdate = computed(() => {
        // Return a range of years starting 13 years earlier than the current year back to 1900.
        return range(yearBirthdateEarliestAllowed, 1900, -1);
    });

    const clearDate = () => {
        selectedMonth.value = "";
        selectedDay.value = "";
        selectedYear.value = "";
    };

    const months = () => {
        return monthsArray;
    };

    const years = () => {
        let data = [];
        let currentYear = dayjs().year();
        let startYear =
            !isNaN(Number(selectedYear.value)) &&
            parseInt(selectedYear.value) < currentYear
                ? parseInt(selectedYear.value)
                : currentYear;

        for (let i = startYear; i <= currentYear + 25; i++) {
            data.push(i);
        }

        return data;
    };

    const isDateValid = computed(() => {
        if (
            selectedMonth.value.toString().length &&
            selectedDay.value.toString().length
        ) {
            const dayjsValidation = dayjs(
                selectedDateFormatted.value,
                "YYYY-MM-DD",
                true,
            ).isValid();

            return dayjsValidation;
        } else {
            return false;
        }
    });

    const isDayValid = () => {
        if (
            !isNaN(Number(selectedDay.value)) &&
            parseInt(selectedDay.value) > days.length
        ) {
            selectedDay.value = "";
        }
    };

    const isValidBirthdate = computed(() => {
        if (
            (selectedYear.value === "",
            selectedMonth.value === "",
            selectedDay.value === "")
        ) {
            return true;
        }

        return (
            isDateValid.value &&
            selectedYear.value <= yearBirthdateEarliestAllowed
        );
    });

    const dateChanged = () => {
        isDayValid();

        let eventDate = {
            date: selectedDateFormatted.value,
            valid: isDateValid(),
        };

        context.emit("dateupdated", eventDate);
    };

    return {
        birthdate,
        clearDate,
        dateChanged,
        days,
        isDateValid,
        isValidBirthdate,
        months,
        selectedDay,
        selectedMonth,
        selectedYear,
        years,
        yearsForBirthdate,
    };
}
